<template>
  <div id="app">
    <div class="grid-x">
      <div class="cell auto">
        <top-bar
          :me="me"
          :should-auto-update="shouldAutoUpdate"
          :is-offline="isOffline"
          :plan="plan"
          @auto-update="autoUpdate"
          @reset-creds="resetCreds"
          @show-settings="showSettings = !showSettings"
          >PCO Live Advanced</top-bar
        >
      </div>
    </div>

    <error-box></error-box>

    <div v-cloak class>
      <WelcomeScreenOauth
        v-if="!isAuthenticated"
        :me="me"
        :auth="auth"
        @update="init"
      />

      <instructions-screen
        :service-types="serviceTypes"
        :has-creds="isAuthenticated"
        @show-settings="showSettingsScreen"
      ></instructions-screen>

      <transition name="fade">
        <div v-if="Object.prototype.hasOwnProperty.call(plan, 'id')" class="grid-container fluid">
          <!-- Show the Folder: Service Plan that is currently running -->

          <service-title :service="currentService" :plan="plan"></service-title>

          <div class="grid-x grid-margin-x">
            <div class="cell medium-8 small-12 large-8">
              <div
                class="card"
                :class="selectedServiceRaw !== undefined ? 'selection' : ''"
              >
                <service-picker
                  @go-to-start="goToStart"
                  @go-to-end="goToEnd"
                  @selected-service="setSelectedService"
                ></service-picker>
                <transition name="collapse">
                  <span
                    v-if="selectedServiceRaw !== undefined"
                    class="warning label width-100 text-center"
                    @click="setSelectedService(undefined)"
                    style="cursor: pointer"
                    title="Click to Return to the Active Service"
                    >Viewing</span
                  >
                </transition>
                <item-table :computed-items="computedItems"></item-table>
              </div>
            </div>
            <div class="cell medium-4 large-4 small-12">
              <controller-live
                :can-midi="canMidi"
                @toggle-control="toggleControl"
                @go-to-next-item="goToNextItem"
                @go-to-previous-item="goToPreviousItem"
              ></controller-live>

              <midi-commands-view
                :midi-commands="midiCommands"
                :can-midi="canMidi"
                @show-settings="showSettings = !showSettings"
              ></midi-commands-view>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div
          v-show="showSettings"
          class="reveal-overlay"
          @click.self="showSettings = false"
        >
          <div class="reveal large">
            <settings-service-editor @update="init"></settings-service-editor>

            <settings-midi
              :computed-items="computedItems"
              :can-midi="canMidi"
            ></settings-midi>

            <h3 class="separator-center">Other Settings</h3>
            <settings-take-control></settings-take-control>
            <SettingsWithingServiceTime />

            <settings-theme ref="settings-theme"></settings-theme>

            <!-- <div class="grid-x grid-padding-x text-left">

            <div class="cell auto">
              <label>Amount of Minutes Before Event to Activate
                <input type="number" min="1" max="60">
              </label>
              <div class="cell auto">
                <button @click="resetCreds()" type="button" class="button" name="button">Reset PCO Credentials</button>
              </div>

            </div>-->

            <button
              @click="showSettings = false"
              class="close-button"
              aria-label="Close alert"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */

import { mapGetters } from 'vuex'
import TopBar from './components/top-bar.vue'
// import SettingsServiceType from './components/settings-service-type.vue'
import SettingsServiceEditor from './components/settings-service-editor.vue'
import SettingsTheme from './components/settings-theme.vue'
import SettingsMidi from './components/settings-midi.vue'
// import SettingsMidiCommand from './components/settings-midi-command.vue'
import SettingsTakeControl from './components/settings-take-control.vue'
import SettingsWithingServiceTime from './components/settings-within-service.vue'

import WelcomeScreenOauth from './components/welcome-screen-oauth.vue'
import ErrorBox from './components/error.vue'
import InstructionsScreen from './components/instructions-screen.vue'
import ServiceTitle from './components/service-title.vue'
import ServicePicker from './components/service-picker.vue'

import ItemTable from './components/item-table.vue'
import ControllerLive from './components/controller-live.vue'
import MidiCommandsView from './components/midi-commands-view.vue'

import './assets/foundation-prototype.css'

export default {
  name: 'App',
  components: {
    TopBar,
    // SettingsServiceType,
    SettingsServiceEditor,
    SettingsTheme,
    SettingsMidi,
    // SettingsMidiCommand,
    SettingsTakeControl,
    SettingsWithingServiceTime,
    WelcomeScreenOauth,
    InstructionsScreen,
    ServiceTitle,
    ServicePicker,
    ItemTable,
    ControllerLive,
    MidiCommandsView,
    ErrorBox,
  },
  data() {
    return {
      // shouldAutoUpdate: false,
      autoUpdateTimer: '',
      autoSetIsWithinServiceTimer: '',
      error: Error(''),
      // isOffline: false,
      // isWithinService: false,
      canMidi: false,
      midiActions: [
        {
          title: 'Go to Next Item',
          value: 0,
          run: function (vueThis) {
            vueThis.goToNextItem()
          },
        },
        {
          title: 'Go to Previous Item',
          value: 1,
          run: function (vueThis) {
            vueThis.goToPreviousItem()
          },
        },
        {
          title: 'Reset Current Service',
          value: 2,
          run: function (vueThis) {
            vueThis.goToStart()
          },
        },
        {
          title: 'Go To End of Service',
          value: 3,
          run: function (vueThis) {
            vueThis.goToEnd()
          },
        },
        {
          title: 'Go to Specific Item',
          value: 4,
          run: function (vueThis, item) {
            // find the item
            const found = vueThis.items.find(
              (each) => each.attributes.title === item
            )
            console.log(found)
            if (found !== undefined && vueThis.isWithinService) {
              vueThis.goToItem(found.id)
            }
          },
        },
        {
          title: 'Go Forward 2 Times',
          value: 5,
          run: function (vueThis) {
            vueThis.goToNextItem().then(() => {
              vueThis.goToNextItem()
            })
          },
        },
        {
          title: 'Go Forward 3 Times',
          value: 6,
          run: function (vueThis) {
            vueThis.goToNextItem().then(() => {
              vueThis.goToNextItem().then(() => {
                vueThis.goToNextItem()
              })
            })
          },
        },
      ],
      showSettings: false,
      currentService: {},
      releaseControlOverride: false,
      urls: {
        services: 'https://api.planningcenteronline.com/services/v2',
      },
      // selectedServiceUnLink: undefined,
    }
  },
  provide: function () {
    return {
      request: this.request,
      urls: this.urls,
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$refs['settings-theme'].loadTheme()
  },
  watch: {
    creds: {
      handler: 'init',
      immediate: false,
    },
  },
  computed: {
    ...mapGetters({
      me: 'getMe',
      auth: 'getAuth',
      isAuthenticated: 'isAuthenticated',
      serviceTypes: 'getServiceTypes',
      midiCommands: 'getMidiCommands',
      items: 'getItems',
      itemTimes: 'getItemTimes',
      plan: 'getPlan',
      liveIncluded: 'getLiveIncluded',
      controller: 'getController',
      hasControl: 'hasControl',
      serviceTypeID: 'getServiceTypeID',
      live: 'getLive',
      planTimes: 'getPlanTimes',
      shouldTakeControl: 'getShouldTakeControl',
      midiEnabled: 'getMidiEnabled',
      isOffline: 'isOffline',
      selectedServiceRaw: 'getSelectedServiceRaw',
      shouldAutoUpdate: 'shouldAutoUpdate',
      currentIndex: 'getCurrentIndex',
      isWithinService: 'isWithinService',
    }),
    itemsWithTimes: function () {
      // for each itemTime
      for (const time of this.itemTimes) {
        // find the first item that matches the itemTime.
        const foundItem = this.items.find(
          (each) => each.id === time.relationships.item.data.id
        )
        // if we didn't find an item that matched the itemTime
        if (foundItem !== undefined) {
          // If the found item has plan_item_times
          if (Object.prototype.hasOwnProperty.call(foundItem.attributes, 'plan_item_times')) {
            // add the itemTime to the item
            foundItem.attributes.plan_item_times.push(time)
          } else {
            // if not, then create the plan_item_times and make a new array with the itemTime
            window.Vue.set(foundItem.attributes, 'plan_item_times', [time])
            // foundItem.attributes.plan_item_times = [time];
          }
        }
      }
      return this.items
    },
    computedItems: function () {
      const computed = []
      let currentSection = -1 // why is this -1?
      // try to sort the array just in case
      // const sortedTimes = this.itemsWithTimes.sort((a, b) => {
      //   if (a.attributes.sequence < b.attributes.sequence) {
      //     return -1
      //   }
      //   if (a.attributes.sequence > b.attributes.sequence) {
      //     return 1
      //   }
      //   // a must be equal to b
      //   return 0;
      // })
      this.itemsWithTimes.map((item, index) => {
        if (item.attributes.item_type === 'header') { // if this item is a header then create a new section and increment our section counter
          computed.push({
            header: item,
            items: [],
          })
          currentSection++
        } else if (index) { // if this item is not the first item of the list then push it to the current section of items
          computed[currentSection].items.push(item)
        } else { // if this item is the first in the list then create a section with no header and increment the section counter
          computed.push({
            header: {},
            items: [item],
          })
          currentSection++
        }
        return false // do nothing
      })
      return computed
    },
    // We need to link the active service and the selectedService up at the start
    // selectedService will follow active service unitl selectedService get changed to something other than the active service
    // if selectedService gets set back to the active service then the link will engage and the selectedService will follow again
    selectedService: {
      get: function () {
        // if (this.selectedServiceUnLink === undefined) {
        //   return this.activeService;
        // } else {
        //   return this.selectedServiceUnLink;
        // }
        return this.$store.getters.getSelectedService
      },
      set: function (newValue) {
        // if (newValue.id === this.activeService.id) {
        //   this.selectedServiceUnLink = undefined;
        // } else {
        //   this.selectedServiceUnLink = newValue;
        // }
        this.$store.commit('setSelectedService', newValue)
      },
    },
    selectedServiceIndex: function () {
      return this.serviceTimes.findIndex(
        (each) => each.id === this.selectedService.id
      )
    },
    selectedServiceItemTimes: function () {
      return this.itemTimes
        .filter(
          (each) => each.relationships.plan_time.data.id === this.selectedService.id
        )
        .filter((each) => !each.attributes.exclude)
    },
    selectedServiceItemTimesUnique: function () {
      return this.selectedServiceItemTimes
        .reverse()
        .reduce((accum, current) => {
          if (
            accum.findIndex(
              (i) => i.relationships.item.data.id
                === current.relationships.item.data.id
            ) < 0
          ) {
            accum.push(current)
          }
          return accum
        }, [])
        .reverse()
    },
    currentServices: function () {
      return this.planTimes.filter(
        (time) => time.attributes.time_type === 'service'
      )
    },
    serviceTimes: function () {
      return this.currentServices.sort((a, b) => {
        const startDateA = new Date(a.attributes.starts_at)
        const startDateB = new Date(b.attributes.starts_at)
        if (startDateA < startDateB) {
          return -1
        }
        if (startDateA > startDateB) {
          return 1
        }
        return 0
      })
    },
    activeService: function () {
      // get the current service
      if (this.serviceTimes === undefined) {
        return {
          id: '',
        }
      }

      if (this.currentItemTime === undefined && this.nextItemTime === null) {
        // get the last service
        return this.lastService
      }

      if (this.currentItemTime === undefined) {
        return {
          id: '',
        }
      }

      const current = this.serviceTimes.find(
        (each) => each.id === this.currentItemTime.relationships.plan_time.data.id
      )
      if (current === undefined) {
        return {
          id: '',
        }
      }
      return current
    },
    activeServiceIndex: function () {
      return this.serviceTimes.findIndex(
        (each) => each.id === this.activeService.id
      )
    },
    activeServiceItemTimes: function () {
      return this.itemTimes
        .filter(
          (each) => each.relationships.plan_time.data.id === this.activeService.id
        )
        .filter((each) => !each.attributes.exclude)
    },
    activeServiceItemTimesUnique: function () {
      return this.activeServiceItemTimes
        .reverse()
        .reduce((accum, current) => {
          if (
            accum.findIndex(
              (i) => i.relationships.item.data.id
                === current.relationships.item.data.id
            ) < 0
          ) {
            accum.push(current)
          }
          return accum
        }, [])
        .reverse()
    },
    lastService: function () {
      if (this.serviceTimes.length > 0) {
        return this.serviceTimes[this.serviceTimes.length - 1]
      }
      return {}
    },
    currentItemTime: function () {
      return this.liveIncluded.find(({ type }) => type === 'ItemTime')
    },
    currentTime: function () {
      const returnTime = {
        itemTime: {},
        canNext: false,
        canPrevious: false,
        isStart: false,
        isEnd: false,
      }
      const currentTime = this.currentItemTime
      console.log('currentTime')
      console.log(currentTime)
      // if (currentTime === undefined) {
      //   return returnTime
      // }
      if (
        currentTime === undefined
        || currentTime.relationships.item.data === null // if there is no item associated with this itemTime then it is either before or after a service
      ) {
        // at the beginning of service
        returnTime.isStart = true
        returnTime.canNext = true
        returnTime.itemTime = currentTime
        console.log(`activeServiceIndex: ${this.activeServiceIndex}`)
        if (this.activeServiceIndex !== 0) {
          // if this isn't the first service
          returnTime.canPrevious = true
        } else {
          returnTime.canPrevious = false
        }
        return returnTime
      }

      if (
        this.live.links.current_item_time === null
        && this.live.links.next_item_time === null
      ) {
        // at the end of service
        returnTime.canPrevious = true
        returnTime.isEnd = true
        // if this isn't the last service
        if (this.activeServiceIndex !== this.serviceTimes.length - 1) {
          returnTime.canNext = true
        } else {
          returnTime.canNext = false
        }
        return returnTime
      }

      returnTime.itemTime = currentTime
      returnTime.canPrevious = true
      returnTime.canNext = true
      return returnTime
    },
    itemTimesOrdered: function () {
      const itemsPerService = this.serviceTimes.map(this.getServiceItems)
      return itemsPerService
        .map((each, index) => {
          each.unshift({
            type: 'ItemTime',
            id: this.serviceTimes[index].id,
            relationships: {
              item: { data: null },
              plan_time: {
                data: { type: 'PlanTime', id: this.serviceTimes[index].id },
              },
            },
          })
          return each
        })
        .flat()
    },
    nextItemTime: function () {
      try {
        if (this.live.relationships.next_item_time.data === null) {
          return null
        }
        return this.live.relationships.next_item_time.data.id
      } catch (error) {
        return undefined
      }
    },
    isEnd: function () {
      return this.currentItemTime === undefined && this.nextItemTime === null
    },
  },
  methods: {
    setSelectedService: function (selection) {
      this.$store.commit('setSelectedService', selection)
    },
    // selectionUnlink: function(selection) {
    //   this.selectedService = selection;
    // },
    showSettingsScreen: function () {
      this.showSettings = true
    },
    init: function () {
      this.loadCreds()
      if (this.isAuthenticated) {
        const vueThis = this
        this.loadMidiCommands()
        this.$store.dispatch('loadServiceTypes').then(async () => {
          window.addEventListener('online', vueThis.isOnline)
          window.addEventListener('offline', vueThis.isOnline)
          // console.log(vueThis.me);
          // console.log(`me: ${JSON.stringify(vueThis.me.hasOwnProperty("id"))}`);
          if (!Object.prototype.hasOwnProperty.call(vueThis.me, 'id')) {
            this.$store.dispatch('getMe')
          }
          // TODO: setup update to run every X amount of minutes ex. 15
          if (vueThis.serviceTypes.length > 0) {
            vueThis.update()
          } else {
            this.$store.commit('reset')
          }

          // setup midiAccess
          try {
            if (navigator.requestMIDIAccess !== undefined) {
              const midiAccess = await navigator.requestMIDIAccess()
              vueThis.canMidi = true
              for (const input of midiAccess.inputs.values()) input.onmidimessage = vueThis.runMidi
            }
          } catch (e) {
            vueThis.$store.commit('setError', e)
            // console.error(e);
          }
        })
      }
    },
    loadMidiCommands: async function () {
      try {
        await this.$store.dispatch('loadMidiCommands')
      } catch (e) {
        // console.log(e);
        // this.error = e;
        this.$store.commit('setError', e)
      }
    },
    setDefaultMidiCommands: async function () {
      await this.$store.dispatch('setDefaultMidiCommands')
    },
    isOnline: function () {
      this.$store.commit('setOffline', !navigator.onLine)
      // this.isOffline = !navigator.onLine;
    },
    autoUpdate: function (start = true) {
      if (start) {
        this.autoUpdateTimer = setInterval(this.updateLive, 5000)
        this.updateLive()
        this.$store.commit('setShouldAutoUpdate', true)
        // this.shouldAutoUpdate = true;
        this.isOnline()
      } else {
        this.$store.commit('setShouldAutoUpdate', false)
        // this.shouldAutoUpdate = false;
        // this.isOffline = true
        clearInterval(this.autoUpdateTimer)
      }
    },
    autoSetIsWithinService: function (start = true) {
      if (start) {
        this.autoSetIsWithinServiceTimer = setInterval(
          this.setIsWithinService,
          5000
        )
        this.setIsWithinService()
      } else {
        clearInterval(this.autoSetIsWithinServiceTimer)
      }
    },
    // Determines which plan to control by finding out which one comes up next out of the saved list
    update: async function () {
      // figure out which service is coming up next
      const upNextPromise = this.serviceTypes.map((service) => this.getNextServicePlan(service.id))

      let nextServices = await Promise.all(upNextPromise)
      // console.log("next Services");
      //
      // console.log(nextServices);
      // filter for errors and false
      nextServices = nextServices.filter((item) => item)
      // console.log(nextServices);
      if (nextServices.length === 0) {
        // this.error = Error("Couldn't find any next service.");
        this.$store.commit(
          'setError',
          Error("Couldn't find any next service.")
        )
        // Reset everything
        return false
      }
      // compare dates on all services
      const currentDate = new Date()
      nextServices.sort((a, b) => {
        const dateA = new Date(a.attributes.sort_date)
        const dateB = new Date(b.attributes.sort_date)
        if (dateA - currentDate < dateB - currentDate) {
          return -1
        } if (dateA - currentDate > dateB - currentDate) {
          return 1
        }
        return 0
      })
      this.$store.commit('setPlan', nextServices[0])
      // this.currentPlan = nextServices[0];
      // const serviceTypeID = nextServices[0].relationships.service_type.data.id;
      this.currentService = this.serviceTypes.find(
        (service) => service.id === this.serviceTypeID
      )

      try {
        await this.$store.dispatch('loadPlanTimes')
        this.autoUpdate()
        this.autoSetIsWithinService()
      } catch (e) {
        // console.error(e);
        // this.error = e;
        this.$store.commit('setError', e)
      }
      return true
    },
    // Updates the PCO live information for the current service
    updateLive: async function () {
      if (!this.isOffline) {
        if (this.plan.id === undefined) {
          return false
        }

        // check to see if the service is over so that you can setup the next one
        if (this.isAfterService()) {
          this.update()
        }

        // double check to make sure the items haven't changed
        try {
          await this.$store.dispatch('getItems')
        } catch (e) {
          if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
            this.isOffline = true
            console.log('I made the error')
            // somehow I need to recheck offline after a new connection happens
            // maybe do setTimeout to recheck isOffline and test the network again
            window.setTimeout(this.isOnline(), 3000)
            return false
          }
          // console.error(e);
          // console.log(`name: ${e.name}`);
          // this.error = e;
          this.$store.commit('setError', e)
        }

        try {
          await this.$store.dispatch('getLive')
          if (!Object.prototype.hasOwnProperty.call(this.live, 'id')) {
            // try again in a second maybe
            console.log('hey I got here')
            return false
          }

          if (this.shouldTakeControl && this.live.attributes.can_control) {
            // if the user has control privelages
            if (
              this.live.attributes.can_take_control
              && !this.hasControl // If the user doesn't have control
              && !this.releaseControlOverride
            ) {
              // If the user is an admin then take control
              this.$store.dispatch('toggleControl')
            } else if ( // If the user isn't an admin then check to see if there is a current controller. If not, then take control
              this.live.relationships.controller.data === null
                && !this.releaseControlOverride
            ) {
              // Is there a current controller
              this.$store.dispatch('toggleControl')
            }
          }
        } catch (e) {
          if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
            this.isOffline = true
            console.log('Live made the error')
            window.setTimeout(this.isOnline(), 3000)
            return false
          }
          console.error(e)
          // this.error = e;
          this.$store.commit('setError', e)
        }
      }
      return true
    },
    toggleControl: async function () {
      try {
        await this.$store.dispatch('toggleControl')

        // if I deliberately click the release control button then
        if (!this.hasControl) {
          // this.shouldTakeControl = false
          // this.$store.commit("setShouldTakeControl", false)
          this.releaseControlOverride = true
        }
        this.updateLive()
      } catch (e) {
        // this.error = e
        // console.error(e);
        this.$store.commit('setError', e)
      }
    },
    loadCreds: async function () {
      if (this.isAuthenticated) {
        await this.$store.dispatch('getAuth')
        await this.$store.dispatch('getMe')
      }
    },
    resetCreds: async function () {
      this.$store.dispatch('resetCreds')
      this.$store.commit('setMe', {})
      // window.open(this.logoutURL)
      // this.clearCreds()
      this.autoUpdate(false)
      this.autoSetIsWithinService(false)
      this.$store.commit('reset')
      // this.$store.commit("setPlan", {})
    },
    request: async function (url, method = 'GET', body = '', etag = '') {
      const authorization = await this.$store.dispatch('getAuth')

      const authHeader = {
        Authorization: authorization,
      }
      const send = {
        method: method,
        headers: authHeader,
      }
      if (body !== '') {
        send.body = body
      }

      if (etag !== '') {
        send.headers['If-None-Match'] = etag
      }

      // send.headers['Access-Control-Allow-Origin'] = 'null'
      return fetch(url, send)
        .then((response) => {
          const { status } = response
          if (status === 200 || status === 201 || status === 202) {
            return response.json().then((json) => ({
              response: json,
              etag: response.headers.get('etag'),
              status: status,
            }))
          } if (status === 204 || status === 304) {
            return {
              response: true,
              etag: response.headers.get('etag'),
              status: status,
            }
          }
          throw response
        })
        .catch((e) => {
          if (Object.prototype.hasOwnProperty.call(e, 'message')) {
            console.log(e)
            throw e
          } else {
            console.dir(e)
            const contentType = e.headers.get('content-type')
            // console.log(`contentType: ${contentType}`);
            if (contentType && contentType.includes('application/json')) {
              return e.json().then((json) => {
                // console.log(`json: ${json.error.message}`);
                const returnError = Error(json.errors[0].detail)
                returnError.name = json.errors[0].title
                throw returnError
              })
            }
            throw e
          }
        })
    },
    getNextServicePlan: function (serviceType, offset = 0) {
      // updated: 20200613
      // plans without service times will not be returned // if there are no service times but there are rehearsal times then the pco api will return empty
      // if the last plan time is a rehearsal and it is after the current time and the
      // current time is after the actual last service time then PCO will still give
      // you the rehearsal time
      // DONT use the last_time_at because you can't guarentee that it is a service time
      const vueThis = this
      const serviceUrl = `${vueThis.urls.services}/service_types/${serviceType}/plans?include=plan_times&order=sort_date&filter=future&per_page=1&offset=${offset}`
      return vueThis
        .request(serviceUrl)
        .then((allPlans) => {
          // first I get all the plans in order of date and limit it to 1

          // if there aren't any plans left then
          if (!allPlans.response.meta.count) {
            return false
          }
          const plan = allPlans.response.data[0]

          // check to see if the last service time is before or after now
          // if the last service time is after now then get the next one
          // if not, then return the current plan

          // filter for service times
          let services = allPlans.response.included.filter(
            (time) => time.attributes.time_type === 'service'
          )

          // if there are no serviceTimes then try and get the next service
          if (services.length === 0) {
            if (plan.relationships.next_plan.data == null) {
              // check before trying to get the next plan if there is even a next plan
              return false
            }

            if (offset === 5) {
              // try 5 times to get the next service plan, if you can't find the next one then forget it
              return false
            }
            // get the next service plan by incrementing the offset
            return vueThis.getNextServicePlan(serviceType, offset + 1)
          }
          // console.log("Services");
          // console.log(services);
          services = services.sort((a, b) => {
            if (a.attributes.starts_at > b.attributes.starts_at) {
              return 1
            } if (a.attributes.starts_at > b.attributes.starts_at) {
              return -1
            }
            return 0
          })
          // get the time of the last service
          const last = services[services.length - 1]

          // let lastDate = new Date(last.attributes.ends_at)
          const lastTime = new Date(
            last.attributes.ends_at // .replace("Z", "") // This fixes a bug where PCO doesn't send the correct formatted time
          )

          // add the plan length to the last time so that we can check if we are before or after the end of service
          // lastTime.setSeconds(
          //   lastTime.getSeconds() + plan.attributes.total_length
          // );
          const currentDate = new Date()
          // console.log(`currentDate: ${currentDate}`);
          // console.log(`lastTime: ${lastTime}`);
          if (currentDate > lastTime) {
            if (plan.relationships.next_plan.data == null) {
              // check before trying to get the next plan if there is even a next plan
              return false
            }

            if (offset === 5) {
              // try 5 times to get the next service plan, if you can't find the next one then forget it
              return false
            }
            // get the next service plan by incrementing the offset
            return vueThis.getNextServicePlan(serviceType, offset + 1)
          }
          return plan
        })
        .catch((e) => {
          console.error(e)
          return false
        })
    },
    goToNextItem: async function (force = false) {
      if (this.isWithinService || force) {
        try {
          await this.$store.dispatch('goToNextItem')
        } catch (e) {
          // this.error = e;
          this.$store.commit('setError', e)
        }
      }
    },
    goToPreviousItem: async function (force = false) {
      if (this.isWithinService || force) {
        try {
          await this.$store.dispatch('goToPreviousItem')
        } catch (e) {
          // console.log("here");
          // console.error(e);
          // this.error = e;
          this.$store.commit('setError', e)
        }
      }
    },
    // goToItemSelected: function (id) {
    //   // example: you are in the first service at position 2 and you need move to the third service position 5
    //   // find what service the item is apart of- easy, check the plan_time id
    //   const pos1 = this.currentTime.itemTime
    //   const pos1Service = this.activeService
    //   const pos1ServiceIndex = this.activeServiceIndex
    //   const pos2Service = this.selectedService
    //   const pos2ServiceIndex = this.selectedServiceIndex
    //   // const pos2 = ?

    //   const pos1Index = this.currentIndex.index
    //   // get the index of the unique selectedService itemTimes
    //   const pos2Index = {
    //     index: this.selectedServiceItemTimesUnique.findIndex(
    //       (itemTime) => itemTime.relationships.item.data.id === id
    //     ),
    //     length: this.selectedServiceItemTimesUnique.length,
    //   }

    //   // let inBetween = []
    //   // let forwards = true
    //   // let pos1ItemsCount = 0
    //   // let pos2ItemsCount = 0
    //   // if (pos1ServiceIndex < pos2ServiceIndex) {
    //   //   inBetween = this.serviceTimes.slice(pos1ServiceIndex+1,pos2ServiceIndex-1)
    //   //   pos1ItemsCount =
    //   // } else if (pos1ServiceIndex > pos2ServiceIndex) {
    //   //   inBetween = this.serviceTimes.slice(pos2ServiceIndex+1,pos1ServiceIndex-1)
    //   //   forwards = false
    //   // } else {
    //   //   inBetween = []
    //   // }

    //   // filter all the services between start and end for excluded item
    //   // count the items in between position 1 and position 2
    //   let totalItems = 0
    //   inBetween.forEach((element) => {
    //     totalItems += this.getServiceItems(element).length
    //   })

    //   // add everything up

    //   // if (this.currentTime.itemTime.relationships.plan_time.data.id === this.selectedService.id && this.currentTime.itemTime.relationships.item.data === null) {
    // },
    // goToItemSelectedV2: function(id) {
    //   // get the full list
    //   const itemsPerService = this.serviceTimes.map(this.getServiceItems);
    //   let allServiceItemTimes = itemsPerService
    //     .map((each, index) => {
    //       each.unshift({
    //         type: "ItemTime",
    //         id: this.serviceTimes[index].id,
    //         relationships: {
    //           item: { data: null },
    //           plan_time: {
    //             data: { type: "PlanTime", id: this.serviceTimes[index].id }
    //           }
    //         }
    //       });
    //       return each;
    //     })
    //     .flat();
    //
    //   // find the index of the item
    //   let pos1Index = 0
    //   if (this.currentItemTime === undefined) {
    //     pos1Index = this.itemTimesOrdered.length
    //   } else {
    //     pos1Index = allServiceItemTimes.findIndex(
    //     each => each.id === this.currentItemTime.id
    //   );
    //   }
    //   console.log("id: " + id);
    //
    //   const pos2Index = allServiceItemTimes.findIndex(each => {
    //     if (each.relationships.item.data === null) {
    //       if (each.relationships.plan_time.data.id === id) {
    //         return true
    //       }
    //       return false
    //     }
    //     if (each.relationships.plan_time.data.id === this.selectedService.id &&
    //         each.relationships.item.data.id === id) {
    //           return true
    //         }
    //       return false
    //   });
    //   console.log("pos1Index: " + pos1Index);
    //   console.log("pos2Index: " + pos2Index);
    //   this.goToIndex(pos1Index, pos2Index);
    // },
    // getServiceItems: function(service) {
    //   return this.itemTimes
    //     .filter(each => each.relationships.plan_time.data.id === service.id)
    //     .filter(each => !each.attributes.exclude)
    //     .reverse()
    //     .reduce((accum, current) => {
    //       if (
    //         accum.findIndex(
    //           i =>
    //             i.relationships.item.data.id ===
    //             current.relationships.item.data.id
    //         ) < 0
    //       ) {
    //         accum.push(current);
    //       }
    //       return accum;
    //     }, [])
    //     .reverse();
    // },
    // To be used for only for the midi action to make sure that it's not dependant on the web UI
    goToItem: async function (id) {
      // get the current position

      // find the position that you are trying to jump
      const goToIndex = this.activeServiceItemTimesUnique.findIndex(
        (itemTime) => itemTime.relationships.item.data.id === id
      )
      this.$store.dispatch('goToIndex', {
        currentIndex: this.currentIndex.index,
        goToIndex: goToIndex,
      })
      // this.goToIndex(currentIndex, goToIndex);
    },
    // getCurrentIndex: function() {
    //   // get the current position
    //   let currentIndex;
    //   if (
    //     this.live.links.current_item_time === null &&
    //     this.live.links.next_item_time === null
    //   ) {
    //     // at the end of service
    //     currentIndex = this.activeServiceItemTimesUnique.length;
    //   } else if (this.currentTime.itemTime.relationships.item.data === null) {
    //     // at the beginning of service
    //     currentIndex = -1;
    //   } else {
    //     currentIndex = this.activeServiceItemTimesUnique.findIndex(
    //       itemTime =>
    //         itemTime.relationships.item.data.id ===
    //         this.currentTime.itemTime.relationships.item.data.id
    //     );
    //   }
    //   return {
    //     index: currentIndex,
    //     length: this.activeServiceItemTimesUnique.length
    //   };
    // },
    // goToIndex: async function(currentIndex, goToIndex) {
    //   // find the position that you are trying to jump
    //   console.log(`currentIndex: ${currentIndex}`);
    //   console.log(`goToIndex: ${goToIndex}`);
    //   let move = 0;
    //   let direction;
    //   let movePromise;
    //   if (currentIndex < goToIndex) {
    //     move = goToIndex - currentIndex;
    //     direction = "next";
    //     for (const i of [...Array(move).keys()]) {
    //       await this.goToNextItem(true);
    //     }
    //     // movePromise = [...Array(move).keys()].map(each => this.goToNextItem(true))
    //   } else {
    //     move = currentIndex - goToIndex;
    //     direction = "previous";
    //     for (const i of [...Array(move).keys()]) {
    //       await this.goToPreviousItem(true);
    //     }
    //     // movePromise = [...Array(move).keys()].map(each => this.goToPreviousItem(true))
    //   }
    //   console.log(`move: ${move}`);
    //   console.log(`direction: ${direction}`);
    // },
    goToStart: function (selectedService = false) {
      if (selectedService) {
        this.$store.dispatch('goToItemSelected', this.selectedService.id)
        // this.goToItemSelectedV2(this.selectedService.id)
      } else {
        this.$store.dispatch('goToIndex', {
          currentIndex: this.currentIndex.index,
          goToIndex: -1,
        })
        // this.goToIndex(this.getCurrentIndex().index, -1);
      }
    },
    goToEnd: function (selectedService = false) {
      // get the next service and go to that
      if (selectedService) {
        console.log(`selectedServiceIndex: ${this.selectedServiceIndex}`)

        const nextIndex = this.selectedServiceIndex + 1
        console.log(`nextIndex: ${nextIndex}`)
        if (nextIndex === this.serviceTimes.length) {
          // If this is the last service and you are trying to go to the end
          // const currentItem = this.currentItemTime
          const currentItemIndex = this.itemTimesOrdered.findIndex(
            (each) => each.id === this.currentItemTime.id
          )
          const goTo = this.itemTimesOrdered.length
          this.$store.dispatch('goToIndex', {
            currentIndex: currentItemIndex,
            goToIndex: goTo,
          })
          // this.goToIndex(currentItemIndex, goTo)
        } else {
          console.log(
            `this.serviceTimes[nextIndex].id: ${
              this.serviceTimes[nextIndex].id}`
          )
          this.$store.dispatch(
            'goToItemSelected',
            this.serviceTimes[nextIndex].id
          )
          // this.goToItemSelectedV2(this.serviceTimes[nextIndex].id)
        }
      } else {
        const currentItem = this.currentIndex
        this.$store.dispatch('goToIndex', {
          currentIndex: currentItem.index,
          goToIndex: currentItem.length,
        })
        // this.goToIndex(currentItem.index, currentItem.length);
      }
    },
    // maybe comment out this function
    goToService: function (serviceIndex, start = true) {
      const currentServiceIndex = this.currentServiceTime

      // build the master list of service items
      const everything = this.serviceTimes.map((serviceTime) => {
        const serviceID = serviceTime.id
        const onlyThisServiceTimes = this.itemTimes
          .filter((each) => each.relationships.plan_time.data.id === serviceID)
          .filter((each) => !each.attributes.exclude)

        // reduce down
        const uniqItems = onlyThisServiceTimes
          .reverse()
          .reduce((accum, current) => {
            if (
              accum.findIndex(
                (i) => i.relationships.item.data.id
                  === current.relationships.item.data.id
              ) < 0
            ) {
              accum.push(current)
            }
            return accum
          }, [])
          .reverse()
        return uniqItems
      })

      // find your current index
      let index = 0
      let i = 0
      for (i; i < currentServiceIndex; i++) {
        index += 1
        index += everything[i].length
      }
      console.log(`currentServiceIndex: ${currentServiceIndex}`)
      console.log(`current index: ${index}`)

      let goTo = 0
      let j = 0
      if (!start) {
        serviceIndex++
      }
      if (start && serviceIndex === 0) {
        goTo = -1
      } else {
        for (j; j < serviceIndex; j++) {
          goTo += 1
          goTo += everything[j].length
        }
      }
      console.log(`go to index: ${goTo}`)

      this.goToIndex(index, goTo)
    },
    setIsWithinService: function () {
      this.$store.dispatch('isWithinService')
    },
    isAfterService: function () {
      const afterTime = this.$store.getters.getWithinServiceTime

      // const planTimes = this.serviceTimes

      const currentDate = new Date()
      if (!Object.prototype.hasOwnProperty.call(this.lastService, 'id')) {
        return true
      }
      const dateEnd = new Date(this.lastService.attributes.ends_at)
      dateEnd.setMinutes(dateEnd.getMinutes() + afterTime)

      // let time = planTimes.filter(time => {
      //   let dateEnd = new Date(time.attributes.ends_at)
      //   // add 15 minutes
      //   dateEnd.setMinutes(dateEnd.getMinutes() + afterTime)
      //   if (currentDate <= dateEnd) {
      //     return false
      //   } else {
      //     return true
      //   }
      // })

      // if the current time is after the end of the last plan time then return true
      if (currentDate >= dateEnd) {
        return true
      }
      return false
    },
    runMidi: function (midiMessage) {
      if (this.midiEnabled) {
        // the midi note is on note C-2 (0)
        // intensity is the preset
        // eslint-disable-next-line eqeqeq
        if (midiMessage.data[0] == 144) {
          // note on
          // eslint-disable-next-line eqeqeq
          if (midiMessage.data[1] == 0) {
            // note C-2
            // create a midi launcher that can find the intensity in the list
            // and launch the midi action
            // filter the midi settings by intensity
            const allMidi = this.midiCommands.filter(
              // eslint-disable-next-line eqeqeq
              (each) => each.note == midiMessage.data[2]
            )

            // run each action
            for (const each of allMidi) {
              console.log(`received midi command: ${each.title}`)
              console.log(
                `within service? ${this.isWithinService ? 'yes' : 'no'}`
              )
              if (each.action === 4) {
                this.midiActions[each.action].run(this, each.options.item)
              } else {
                this.midiActions[each.action].run(this)
              }
            }
            // if (midiMessage.data[2] == 0) {
            //   console.log("Go to Next")
            //   this.goToNextItem()
            // } else if (midiMessage.data[2] == 1) {
            //   console.log("Go to previous")
            //   this.goToPreviousItem()
            // } else if (midiMessage.data[2] == 5) {
            //   console.log("Reset live data")
            //   // this.resetLive()
            // } else {
            //   console.log("Do nothing")
            // }
          // eslint-disable-next-line eqeqeq
          } else if (midiMessage.data[1] == 1) {
            // Legacy
            // eslint-disable-next-line eqeqeq
            if (midiMessage.data[2] == 1) {
              console.log('Go to Next (Legacy)')
              this.goToNextItem()
            }
          }
        }
      }
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-in-enter-active {
  transition: opacity 0.2s;
}

.fade-in-enter

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 500px;
  opacity: 1;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
  max-height: 0;
}

.list-leave,
.list-enter-to

/* .list-leave-active below version 2.1.8 */ {
  opacity: 1;
  max-height: 300px;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 1s ease;
}

.collapse-enter,
.collapse-leave-to

/* .list-leave-active below version 2.1.8 */ {
  max-height: 0;
  /* height: 0; */
}

.collapse-leave,
.collapse-enter-to

/* .list-leave-active below version 2.1.8 */ {
  max-height: 300px;
  /* height: 1em; */
}

/* .transition-grow {
height: 5em;
max-height: 6em;
transition: all 4s;
} */

/* .list-height-enter-active,
.list-height-leave-active {
  transition: all 1s;
} */

body {
  background: var(--bg-color);
  color: var(--text-color);
  /* transition: all 1s */
}

label {
  color: var(--text-color);
}

.label.success {
  color: var(--white-text);
  background-color: var(--green);
}

.button {
  background-color: var(--blue);
}

.button.alert {
  background-color: var(--red);
}

.switch-paddle {
  filter: brightness(var(--brightness));
}

input[type="number"] {
  background-color: var(--selected-color);
  color: var(--text-color);
}

input[type="number"]:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}

input[type="text"] {
  background-color: var(--selected-color);
  color: var(--text-color);
}

input[type="text"].success {
  border-color: var(--green-border);
  background-color: var(--green);
}

input[type="text"]:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}

select {
  color: var(--text-color);
  background: var(--bg-color);
}

select:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}

thead {
  background: var(--secondary);
  color: var(--secondary-color);
  border: 1px solid var(--border);
}

tbody {
  background: var(--bg-color);
  color: var(--secondary-color);
  border: 1px solid var(--border);
}

tbody tr:nth-child(even) {
  background-color: var(--border);
}

.callout {
  transition: all 1s;
  background-color: var(--selected-color);
  color: var(--text-color);
}

.callout.secondary {
  background-color: var(--secondary-callout-default);
  color: var(--white-text);
}

.callout.secondary label {
  color: var(--white-text);
}

.close-button {
  color: #333;
}

.current {
  /* border-color: black;
border-style: solid;
border-width: 10px; */
  color: var(--white-text);
  background-color: var(--green) !important;
  transition: background-color 0.2s;
}

/* .transition-grow.current {
max-height: 4em;
height: 4em;
} */

.item-row {
  height: 4.3rem;
}

.card {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border);
}

.card-divider {
  background-color: var(--secondary-callout);
  color: var(--white-text);
}

.card.selection {
  border: 3px solid #ffae00;
}

.accordion .accordion-item {
  background: var(--bg-color);
}

.accordion .accordion-item.is-active {
  background: var(--secondary);
}

.accordion .accordion-item.is-active .accordion-title {
  color: var(--accordion-text);
}

.accordion {
  background: #fefefe;
  /* border: 1px solid var(--border); */
}

.accordion-title {
  color: var(--accordion-text);
  border-bottom: 1px solid var(--secondary);
  border-color: var(--border);
}

.accordion-title:hover,
.accordion-title:focus {
  background: var(--secondary);
}

.description {
  white-space: pre-line;
}

.accordion-content {
  display: block;
  border-bottom: 1px solid var(--secondary);
  border-color: var(--secondary);
  background: var(--bg-color);
  color: var(--text-color);
}

.reveal {
  display: block;
  background-color: var(--secondary-callout-default);
  color: var(--white-text);
  border-color: var(--border);
}

.reveal-overlay {
  display: block;
}

[v-cloak] {
  display: none;
}
</style>
