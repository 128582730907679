<template>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-9">
      <label for="takeControlSetting" class="middle"
        >Always Try and Take Control</label
      >
    </div>
    <div class="cell medium-2 small-3">
      <div class="switch">
        <input
          class="switch-input"
          v-model="shouldTakeControl"
          id="takeControlSetting"
          type="checkbox"
          name="takeControlSetting"
        />
        <label class="switch-paddle" for="takeControlSetting">
          <span class="show-for-sr">Always Try and Take Control</span>
        </label>
      </div>
    </div>
    <div class="cell medium-7">
      <label
        >PCO Live Advanced will always try and take control. So when someone
        releases control, this will automatically take it.</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-take-control',
  data() {
    return {}
  },
  mounted() {},
  computed: {
    shouldTakeControl: {
      get: function () {
        // check local storage
        return this.$store.getters.getShouldTakeControl
      },
      set: function (newValue) {
        return this.$store.commit('setShouldTakeControl', newValue)
      },
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
