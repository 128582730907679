import Vue from 'vue'
import vuejsStorage from 'vuejs-storage'
import App from './App.vue'

// import { store } from "./store/index"
import store from './store'

Vue.config.productionTip = false
Vue.use(vuejsStorage)

window.Vue = Vue

new Vue({
  // el: '#app',
  store,
  render: h => h(App)
}).$mount('#app')
