<template>
  <div>
    <h3 class="separator-center">Service Editor</h3>
    <!-- The service Editor -->
    <div id="serviceEditor">
      <div class="grid-x serviceType">
        <div class="cell">
          <!--<transition-group name="list" tag="p">-->
          <table>
            <thead>
              <th>Name</th>
              <th>id</th>
              <th>Folder</th>
              <th>PCO Link</th>
              <th v-if="editor && serviceTypes.length !== 0"></th>
            </thead>
            <tbody>
              <tr v-if="serviceTypes.length === 0">
                <td colspan="4">No Service Types found.</td>
              </tr>
              <template v-for="serviceType in serviceTypes">
                <settings-service-type
                  :key="serviceType.id"
                  :service-type="serviceType"
                  :editor="editor"
                  @remove-service="removeService"
                ></settings-service-type>
              </template>
            </tbody>
          </table>
          <!--</transition-group>-->
        </div>
      </div>
    </div>

    <!-- New service Creator -->
    <div class="grid-x align-center">
      <div class="cell medium-6 small-12">
        <transition name="expand">
          <div v-if="showAddServiceType" class="callout">
            <h5>Add Service Type</h5>
            <div class="grid-x text-left">
              <div class="cell medium-4 small-4">
                <label class="middle" for="addFolder">Folder</label>
              </div>
              <div class="cell medium-8 small-8">
                <select
                  id="addFolder"
                  v-model="folderSelected"
                  @change="pcoLoadTypesFromFolder()"
                >
                  <option value="0">Select a Folder</option>
                  <option
                    v-for="option in pcoFolders"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.attributes.name }}
                  </option>
                </select>
              </div>
            </div>
            <transition name="fade">
              <div v-if="folderSelected" class="grid-x">
                <div class="cell medium-4 small-4">
                  <label class="middle" for="addServiceType"
                    >Service Type</label
                  >
                </div>
                <div class="cell medium-8 small-8">
                  <select id="addServiceType" v-model="serviceTypeSelected">
                    <option value="0">Select a Service</option>
                    <option
                      v-for="option in pcoServiceTypes"
                      :key="option.id"
                      :value="option.id"
                      :disabled="hasService(option.id)"
                    >
                      {{ option.attributes.name }}
                    </option>
                  </select>
                </div>
              </div>
            </transition>
            <div class="button-group float-center">
              <a @click="showAddServiceType = false" class="secondary button"
                >Cancel</a
              >
              <transition name="fade">
                <a
                  v-if="serviceTypeSelected"
                  @click="saveServiceType"
                  class="success button"
                  >Add</a
                >
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!-- Add Service Button -->
    <div id class="grid-x">
      <div class="cell small-6 align-left">
        <div v-if="!showAddServiceType" class="button-group stacked-for-small">
          <button @click="pcoLoadFolders" type="button" class="button">
            Add Service Type
          </button>
          <!-- <button type="button" class="button">Add Specific Event</button> -->
          <button
            v-if="!editor && serviceTypes.length !== 0"
            @click="editor = true"
            type="button"
            class="button"
          >
            Edit
          </button>
          <button
            v-else-if="editor && serviceTypes.length !== 0"
            @click="editor = false"
            type="button"
            class="button"
          >
            Done
          </button>
        </div>
      </div>
      <div class="cell small-6 align-right">
        <button
          @click="showImportExport = !showImportExport"
          type="button"
          class="button small float-right"
          name="button"
        >Import/Export</button>
      </div>
      <transition name="expand">
        <div v-if="showImportExport" class="cell medium-12">
          <h5>Import/Export Service Types</h5>
          <button
            @click="importServiceTypes(true)"
            type="button"
            class="button small float-right"
            name="button"
          >Clear and Import</button>
          <button
            @click="importServiceTypes"
            type="button"
            class="button small float-right"
            name="button"
          >Import</button>
          <label>
            Import
            <textarea placeholder="Paste data here" v-model="importJSON"></textarea>
          </label>
          <label>
            Export
            <textarea :value="exportJSON"></textarea>
          </label>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import { openDB, deleteDB, wrap, unwrap } from 'idb'
import SettingsServiceType from './settings-service-type.vue'

export default {
  name: 'settings-service-editor',
  components: {
    SettingsServiceType,
  },
  data() {
    return {
      editor: false,
      // serviceTypes: [],
      folderSelected: 0,
      serviceTypeSelected: 0,
      pcoFolders: [],
      pcoServiceTypes: [],
      showAddServiceType: false,
      dbName: 'PCO-Live-Midi',
      dbVersion: 1,
      dbStoreName: 'serviceTypes',
      dbMidiActionName: 'midiActions',
      importJSON: '',
      showImportExport: false
    }
  },
  inject: ['urls', 'request'],
  mounted() {
    // this.loadStoredServiceTypes();
    // this.$store.dispatch("loadServiceTypes")
  },
  computed: {
    serviceTypes: function () {
      return this.$store.getters.getServiceTypes
    },
    exportJSON: function () {
      return JSON.stringify(this.$store.getters.getServiceTypes)
    }
  },
  methods: {
    importServiceTypes: async function(clear = false) {
      // Show a dialog to imput text
      // then show a button to import
      let data
      try {
        data = JSON.parse(this.importJSON)
      } catch (e) {
        console.error(e)
        return false
      }

      try {
        if (clear) {
          await this.$store.dispatch("removeAllServiceTypes")
        }
        await this.$store.dispatch('importServiceTypes', data)
        this.$emit('update')
        this.importJSON = ""
      } catch (error) {
        console.error(error)
        this.error = error
      }
     
      return true
    },
    saveServiceType: async function () {
      // store the serviceType to the DB
      const vueThis = this
      const folder = this.pcoFolders.find(
        (tempFolder) => tempFolder.id === vueThis.folderSelected
      )
      const serviceType = this.pcoServiceTypes.find(
        (tempServiceType) => tempServiceType.id === vueThis.serviceTypeSelected
      )
      const newService = {
        folder: {
          name: folder.attributes.name,
          id: this.folderSelected,
        },
        name: serviceType.attributes.name,
        id: this.serviceTypeSelected,
        override: false,
        planSelected: 0,
      }
      // console.log(newService);

      try {
        // await this.dbAddService(newService);
        await this.$store.dispatch('addServiceType', newService)
        // this.serviceTypes.push(newService);
        this.showAddServiceType = false
        // reset the form
        this.pcoServiceTypes = []
        this.serviceTypeSelected = 0
        this.folderSelected = 0

        // recheck all the services to see which one is first
        this.$emit('update')
      } catch (e) {
        this.error = e
      }
    },
    pcoLoadFolders: async function () {
      this.showAddServiceType = true

      // load the folders
      try {
        const allFolders = await this.request(`${this.urls.services}/folders`)
        // console.log(allFolders);
        this.pcoFolders = allFolders.response.data
      } catch (e) {
        this.showAddServiceType = false
        this.pcoFolders = []
        this.error = e
      }
    },
    pcoLoadTypesFromFolder: async function () {
      try {
        const serviceTypes = await this.request(
          `${this.urls.services}/folders/${this.folderSelected}/service_types`
        )
        // console.log(serviceTypes)
        this.pcoServiceTypes = serviceTypes.response.data
        // return serviceTypes.data
      } catch (e) {
        this.pcoServiceTypes = []
        this.error = e
      }
    },
    // loadStoredServiceTypes: async function() {
    //   try {
    //     this.serviceTypes = await this.dbGetStoredServices();
    //   } catch (e) {
    //     this.error = e;
    //   }
    // },
    removeService: function (id) {
      // Remove from db and from vue
      this.$store.dispatch('removeServiceType', id)
      // this.dbRemoveService(id);
      // const index = this.serviceTypes.findIndex(each => each.id === id);
      // if (index !== -1) {
      //   this.serviceTypes.splice(index, 1);
      // }

      // turn edit mode off if there are no more types in the list
      if (this.serviceTypes.length === 0) {
        this.editor = false
      }

      // re-init the main page
      this.$emit('update')
    },
    // If the service already exists in our list
    hasService: function (id) {
      // eslint-disable-next-line eqeqeq
      if (this.serviceTypes.findIndex((type) => type.id == id) === -1) {
        return false
      }
      return true
    },
    // dbAddService: async function(data) {
    //   let vueThis = this;
    //   const db = await openDB(this.dbName, this.dbVersion, {
    //     upgradeDB(db) {
    //       db.createObjectStore(vueThis.dbStoreName, {
    //         keyPath: "id"
    //       });
    //       db.createObjectStore(vueThis.dbMidiActionName, {
    //         keyPath: "id",
    //         autoIncrement: true
    //       });
    //     }
    //   });
    //
    //   return db.add(this.dbStoreName, data);
    // },
    // dbRemoveService: async function(id) {
    //   let vueThis = this;
    //   const db = await openDB(this.dbName, this.dbVersion, {
    //     upgradeDB(db) {
    //       db.createObjectStore(vueThis.dbStoreName, {
    //         keyPath: "id"
    //       });
    //       db.createObjectStore(vueThis.dbMidiActionName, {
    //         keyPath: "id",
    //         autoIncrement: true
    //       });
    //     }
    //   });
    //
    //   try {
    //     console.log(id);
    //     await db.delete(this.dbStoreName, id);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // dbRemoveAllServices: async function() {
    //   let vueThis = this;
    //   let db;
    //   try {
    //     db = await openDB(vueThis.dbName, vueThis.dbVersion, {
    //       upgrade(db) {}
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   try {
    //     await db.clear(vueThis.dbStoreName);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // dbGetStoredServiceById: async function(id) {
    //   const db = await openDB(this.dbName, this.dbVersion);
    //
    //   return await db.get(id.toString());
    // },
    // dbGetStoredServices: async function() {
    //   console.log(`dbname: ${this.dbName}`);
    //   console.log(`dbVersion: ${this.dbVersion}`);
    //   console.log(`dbStoreName: ${this.dbStoreName}`);
    //   let vueThis = this;
    //   let db;
    //   try {
    //     db = await openDB(vueThis.dbName, vueThis.dbVersion, {
    //       upgrade(db) {
    //         const store = db.createObjectStore(vueThis.dbStoreName, {
    //           keyPath: "id"
    //         });
    //         db.createObjectStore(vueThis.dbMidiActionName, {
    //           keyPath: "id",
    //           autoIncrement: true
    //         });
    //         store.createIndex("name", "name");
    //       }
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   let all = [];
    //   try {
    //     all = await db.getAllFromIndex(vueThis.dbStoreName, "name");
    //   } catch (e) {
    //     // there are currently no stored objects
    //     console.log(e);
    //   }
    //   console.log(all);
    //   return all;
    // }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
