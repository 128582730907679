import { openDB } from 'idb'

export default {
  state: () => ({
    dbName: 'PCO-Live-Midi',
    dbVersion: 1,
    dbStoreName: 'serviceTypes',
    dbMidiActionName: 'midiActions',
    dbIndex: 'name',
    serviceTypes: []
  }),
  mutations: {
    addServiceType: (state, payload) => {
      state.serviceTypes.push(payload)
    },
    setServiceTypes: (state, payload) => {
      state.serviceTypes = payload
    },
    removeServiceType: (state, id) => {
      const index = state.serviceTypes.findIndex(each => each.id === id)
      if (index !== -1) {
        state.serviceTypes.splice(index, 1)
      }
    },
    clearStore: (state) => {
      state.serviceTypes = []
    }
  },
  getters: {
    getServiceTypes: (state) => state.serviceTypes.sort((a, b) => a.name.localeCompare(b.name)),
    getDBMidiActionName: state => state.dbMidiActionName
  },
  actions: {
    importServiceTypes: ({ dispatch }, payload) => {
      const dbPromises = payload.map(command => dispatch('addServiceType', command))
      return Promise.all(dbPromises)
    },
    // eslint-disable-next-line arrow-body-style
    openDB: ({ state, dispatch }) => {
      return openDB(state.dbName, state.dbVersion, {
        upgrade(db) {
          const store = db.createObjectStore(state.dbStoreName, {
            keyPath: 'id'
          })
          db.createObjectStore(state.dbMidiActionName, {
            keyPath: 'id',
            autoIncrement: true
          })
          store.createIndex(state.dbIndex, state.dbIndex)
          return dispatch('setDefaultMidiCommands')
        }
      })
    },
    addServiceType: async ({ dispatch, commit, state }, payload) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.log('there was an error opening the database')
        console.error(e)
        throw e
      }

      let result
      try {
        console.log(payload);
        result = await db.add(state.dbStoreName, payload)
        commit('addServiceType', payload)
      } catch (e) {
        console.log('there was an error adding to the database')
        console.error(e)
        throw e
      }
      
      return result
    },
    loadServiceTypes: async ({ dispatch, commit, state }) => {
      // console.log(`dbname: ${state.dbName}`);
      // console.log(`dbVersion: ${state.dbVersion}`);
      // console.log(`dbStoreName: ${state.dbStoreName}`);
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.log('there was an error setting up the database')
        console.error(e)
        throw e
      }
      let all = []
      try {
        all = await db.getAllFromIndex(state.dbStoreName, 'name')
        commit('setServiceTypes', all)
        return all
      } catch (e) {
        // there are currently no stored objects
        console.error(e)
        throw e
      }
    },
    removeServiceType: async ({ dispatch, commit, state }, id) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.log('there was an error opening the database')
        console.error(e)
        throw e
      }

      try {
        await db.delete(state.dbStoreName, id)
        commit('removeServiceType', id)
      } catch (e) {
        console.log('there was an error removing a service type from the database')
        console.error(e)
        throw e
      }
    },
    removeAllServiceTypes: async ({ dispatch, commit, state }) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.log('there was an error opening the database')
        console.error(e)
        throw e
      }
      try {
        await db.clear(state.dbStoreName)
        commit('clearStore')
      } catch (e) {
        console.log('there was an error removing all serviceTypes from the database')
        console.error(e)
        throw e
      }
    }
  }
}
