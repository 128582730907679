export default {
  state: () => ({
    // dbName: "PCO-Live-Midi",
    // dbVersion: 1,
    // dbStoreName: "serviceTypes",
    // dbMidiActionName: "midiActions",
    midiCommandsDefault: [
      {
        title: 'Go to Next Item',
        action: 0,
        note: '0'
      },
      {
        title: 'Go to Previous Item',
        action: 1,
        note: '1'
      },
      {
        title: 'Reset Current Service',
        action: 2,
        note: '2'
      },
      {
        title: 'Go To End of Service',
        action: 3,
        note: '3'
      }
    ],
    midiCommands: [],
    enabled: true
  }),
  mutations: {
    addMidiCommand: (state, payload) => {
      state.midiCommands.push(payload)
    },
    setMidiCommands: (state, payload) => {
      state.midiCommands = payload
    },
    removeMidiCommand: (state, id) => {
      const index = state.midiCommands.findIndex(each => each.id === id)
      if (index !== -1) {
        state.midiCommands.splice(index, 1)
      }
    },
    clearMidiCommands: (state) => {
      state.midiCommands = []
    },
    updateMidiCommand: (state, command) => {
      const index = state.midiCommands.findIndex(each => each.id === command.id)
      if (index !== -1) {
        state.midiCommands[index] = command
      } else {
        state.midiCommands.push(command)
      }
    },
    setMidiEnabled: (state, payload) => {
      state.enabled = payload
    }
  },
  getters: {
    getMidiCommands: (state) => state.midiCommands,
    getMidiEnabled: state => state.enabled,
    getMidiCommandsDefault: state => state.midiCommandsDefault
  },
  actions: {
    importMidiCommands: ({ dispatch }, payload) => {
      // await dispatch("removeAllMidiCommands")
      const dbPromises = payload.map(command => dispatch('addMidiCommand', command))

      return Promise.all(dbPromises)
    },
    addMidiCommand: async ({ dispatch, commit, getters }, payload) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.error(e)
        throw e
      }
      const result = await db.add(getters.getDBMidiActionName, payload)
      payload.id = result
      commit('addMidiCommand', payload)
      return result
    },
    loadMidiCommands: async ({ dispatch, commit, getters }) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.error(e)
        throw e
      }
      let all = []
      try {
        all = await db.getAll(getters.getDBMidiActionName)
        commit('setMidiCommands', all)
        return all
      } catch (e) {
        // there are currently no stored objects
        console.error(e)
        throw e
      }
    },
    removeMidiCommand: async ({ dispatch, commit, getters }, id) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.error(e)
        throw e
      }

      try {
        await db.delete(getters.getDBMidiActionName, id)
        commit('removeMidiCommand', id)
        return true
      } catch (e) {
        console.error(e)
        return e
      }
    },
    removeAllMidiCommands: async ({ dispatch, commit, getters }) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.error(e)
        throw e
      }
      try {
        await db.clear(getters.getDBMidiActionName)
        commit('clearMidiCommands')
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    updateMidiCommand: async ({ dispatch, commit, getters }, command) => {
      let db
      try {
        db = await dispatch('openDB')
      } catch (e) {
        console.error(e)
        throw e
      }
      try {
        const result = await db.put(getters.getDBMidiActionName, command)
        command.id = result
        commit('updateMidiCommand', command)
        return result
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    setDefaultMidiCommands: async ({ dispatch, getters }) => {
      // await dispatch("removeAllMidiCommands")
      const dbPromises = getters.getMidiCommandsDefault.map(command => dispatch('addMidiCommand', command))

      await Promise.all(dbPromises)
    },
  }
}
