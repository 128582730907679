<template>
  <transition name="fade">
    <div v-if="serviceTypes.length === 0 && hasCreds" class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell full text-center">
          <h4>No Saved Service Types Found.</h4>
          <p>Add a Service Type from PCO to track and control.</p>
          <button
            @click="$emit('show-settings', true)"
            type="button"
            class="button radius"
          >Add Services</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'instructions-screen',
  props: {
    serviceTypes: {
      type: Array,
      default: () => []
    },
    hasCreds: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
