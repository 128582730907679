<template>
  <tbody>
    <item-row
      v-for="item in section.items"
      :key="item.id"
      :item="item"
      ></item-row>
  </tbody>
</template>

<script>
import ItemRow from './item-row.vue'

export default {
  name: 'item-section',
  components: {
    ItemRow
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
