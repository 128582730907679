<template>
  <table>
    <thead>
      <tr>
        <th width>Length</th>
        <th>Item</th>
        <th style="width: 7em" class="text-center">Goto</th>
      </tr>
    </thead>
    <template v-if="computedItems.length === 0">
      <item-section-header
        :header="{
          attributes: {
            title: 'No Items Found',
          },
        }"
      ></item-section-header>
    </template>
    <template v-else v-for="section in computedItems">
      <item-section-header
        v-if="section.header.hasOwnProperty('id')"
        :key="section.header.id"
        :header="section.header"
      ></item-section-header>
      <item-section
        v-if="section.items.length !== 0"
        :key="section.items[0].id"
        :section="section"
      ></item-section>
    </template>
  </table>
</template>

<script>
import ItemSectionHeader from './item-section-header.vue'
import ItemSection from './item-section.vue'

export default {
  name: 'item-table',
  components: {
    ItemSectionHeader,
    ItemSection,
  },
  props: {
    computedItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
