const urls = {
  services: 'https://api.planningcenteronline.com/services/v2'
}

export default {
  state: () => ({
    basic: {
      appID: '',
      secret: ''
    },
    oauth: {},
    me: {},
    logoutURL: 'connect/pco?custom_params%5Bforce_login%5D=true'
  }),
  mutations: {
    setOauth: (state, payload) => {
    //   console.log('before test')
      if (Object.prototype.hasOwnProperty.call(payload, 'access_token')) {
        console.log({ payload })
        state.oauth = {
          access_token: payload.access_token,
          raw: {
            token_type: payload.raw.token_type,
            created_at: payload.raw.created_at,
            expires_in: payload.raw.expires_in
          }
        }
        state.me = payload.profile.data // add this to get the info faster
      } else {
        // console.log('before set to empty')
        state.oauth = {}
      }
    },
    setBasic: (state, payload) => {
      state.basic = payload
    },
    clearCreds: state => {
      state.basic.appID = ''
      state.basic.secret = ''
      state.oauth = {}
      state.me = {}
    },
    // setMe: (state, payload) => {
    //   state.me = {
    //     id: payload.id
    //   }
    // },
    setMe: (state, payload) => {
      state.me = payload
    },
    resetMe: (state) => {
      state.me = {}
    }
  },
  getters: {
    isAuthenticated: state => (Object.prototype.hasOwnProperty.call(state.oauth, 'access_token') || (state.basic.appID !== '' && state.basic.secret !== '')
    ),
    getAuth: state => ({
      oauth: state.oauth,
      basic: state.basic,
      me: state.me
    }),
    getMe: state => state.me,
  },
  actions: {
    refreshToken: async context => {
      console.log('Refreshing')
      try {
        const url = '/refresh'
        const result = await fetch(url)
        const jsonResult = await result.json()
        if (Object.prototype.hasOwnProperty.call(jsonResult, 'raw')) {
          if (
            Object.prototype.hasOwnProperty.call(jsonResult.auth.raw, 'error')
          ) {
            const returnError = new Error(
              `${jsonResult.auth.raw.error_description}`
            )
            returnError.name = jsonResult.auth.raw.error
            throw returnError
          }
          console.log({ jsonResult })
        //   console.log(jsonResult.auth.raw)
        } else if (!Object.prototype.hasOwnProperty.call(jsonResult, 'auth')) {
          context.commit('clearCreds')
          context.commit('reset')
        }
        // console.log('before setOauth')
        context.commit('setOauth', jsonResult.auth)
      } catch (error) {
        // error.name = "Unable to Refresh Token"
        // console.log("{error}");
        console.log('before error')
        console.error(error)
        context.commit('setError', error)
        // throw error
      }
    },
    resetCreds: async ({ commit, state }) => {
      // Check to see if the user was logged in via oauth or basic
      if (Object.prototype.hasOwnProperty.call(state.oauth, 'access_token')) {
        window.open(state.logoutURL)
      }
      commit('clearCreds')
    },
    getAuth: async ({ getters, dispatch }) => {
      if (
        Object.prototype.hasOwnProperty.call(
          getters.getAuth.oauth,
          'access_token'
        )
      ) {
        // check expiraton on token
        // refresh if needed
        const now = new Date()

        // Bug fix: PCO sends you the created_at date with three digits too short
        const then = new Date(getters.getAuth.oauth.raw.created_at * 1000)
        then.setSeconds(
          then.getSeconds() + parseInt(getters.getAuth.oauth.raw.expires_in, 10)
        )
        // then.setSeconds(then.getSeconds() + 120)

        if (now > then) {
          // refresh token
          try {
            await dispatch('refreshToken')
          } catch (e) {
            // eslint-disable-next-line no-throw-literal
            throw 'Could not Refresh Token'
          }
          if (getters.getAuth.oauth.raw === undefined) {
            // this happens when not authenticated and previously was
            // eslint-disable-next-line no-throw-literal
            throw 'Refresh empty'
          }
        }
        // console.log("I got Here");
        // console.dir(getters.getAuth);
        return `${getters.getAuth.oauth.raw.token_type} ${getters.getAuth.oauth.access_token}`
      }
      return `Basic ${window.btoa(
        `${getters.getAuth.basic.appID}:${getters.getAuth.basic.secret}`
      )}`
    },
    request: async (
      { dispatch },
      { url, method = 'GET', body = '', etag = '' }
    ) => {
      const authorization = await dispatch('getAuth')

      const authHeader = {
        Authorization: authorization
      }
      const send = {
        method: method,
        headers: authHeader
      }
      if (body !== '') {
        send.body = body
      }

      if (etag !== '') {
        send.headers['If-None-Match'] = etag
      }

      // send.headers['Access-Control-Allow-Origin'] = 'null'
      return fetch(url, send)
        .then(response => {
          const { status } = response
          if (status === 200 || status === 201 || status === 202) {
            return response.json().then(json => ({
              response: json,
              etag: response.headers.get('etag'),
              status: status
            }))
          } if (status === 204 || status === 304) {
            return {
              response: true,
              etag: response.headers.get('etag'),
              status: status
            }
          }
          throw response
        })
        .catch(e => {
          if (Object.prototype.hasOwnProperty.call(e, 'message')) {
            console.log(e)
            throw e
          } else {
            console.dir(e)
            const contentType = e.headers.get('content-type')
            // console.log(`contentType: ${contentType}`);
            if (contentType && contentType.includes('application/json')) {
              return e.json().then(json => {
                // console.log(`json: ${json.error.message}`);
                const returnError = Error(json.errors[0].detail)
                returnError.name = json.errors[0].title
                throw returnError
              })
            }
            throw e
          }
        })
    },
    getMe: async ({ dispatch, commit, getters }) => {
      if (Object.prototype.hasOwnProperty.call(getters.getMe, 'id')) {
        return
      }
      try {
        const me = await dispatch('request', {
          url: `${urls.services}/me`
        })
        commit('setMe', me.response.data)
      } catch (e) {
        if (e.name === 'Unauthorized') {
          // clear the creds
          commit('clearCreds')
        }
        commit('setMe', {})
        throw e
      }
    }
  }
}
