<template>
  <div v-if="canMidi" class="card">
    <div class="card-section">
      <h5>Midi Receive Settings</h5>
      <table class="midi-commands">
        <thead>
          <th>Command</th>
          <th>Note</th>
          <th>Int.</th>
        </thead>
        <tbody>
          <tr v-for="midi in midiCommands" :key="midi.id">
            <td class="midi-title">
              {{ midi.title
              }}<template v-if="midi.hasOwnProperty('options')"
                >: {{ midi.options.item }}</template
              >
            </td>
            <td>C-2 (0)</td>
            <td>{{ midi.note }}</td>
          </tr>
        </tbody>
      </table>
      <div class="button-group">
        <button
          @click="$emit('show-settings', true)"
          type="button"
          class="button"
          name="button"
        >
          Edit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'midi-commands-view',
  props: {
    midiCommands: {
      type: Array,
      default: () => [],
    },
    canMidi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
