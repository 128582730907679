<template>
  <span>
    <button
      @click="show = !show"
      class="dropdown button"
      :class="{ 'arrow-only': buttonText === '' }"
      :title="buttonTitle"
    >
      {{ buttonText }}
    </button>
    <transition name="expand">
      <div v-show="show" class="dropdown-pane" :class="[positionClass]">
        <div class="dropdown-pane-overlay" @click.self="show = false"></div>
        <div @click="show = false" class="content" style="position: relative">
          <slot></slot>
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  name: 'dropdown',
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: 'Click for Dropdown',
    },
    position: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right', 'top', 'bottom'].indexOf(value) !== -1 // The value must match one of these strings
      ,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    positionClass: function () {
      if (this.position === 'left') {
        return 'left'
      }
      if (this.position === 'right') {
        return 'dropdown-pane-right text-right'
      }
      if (this.position === 'top') {
        return 'dropdown-pane-top'
      }
      if (this.position === 'bottom') {
        return 'dropdown-pane-bottom'
      }
      return 'left'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown-pane-right {
  right: 0;
}
.dropdown-pane-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  /* width: 300px; */
  width: auto;
  padding: 1rem;
  visibility: visible;
  border: 1px solid var(--selected-color);
  border-radius: 0;
  background: var(--bg-color);
  font-size: 1rem;
  /* right: 0; */
  /* margin-top: -1rem; */
}
</style>
