export default {
  state: () => ({
    shouldTakeControl: true,
    theme: 'System',
    withinServiceTime: 15,
  }),
  mutations: {
    setShouldTakeControl: (state, payload) => {
      state.shouldTakeControl = payload
    },
    setTheme: (state, payload) => {
      state.theme = payload
    },
    setWithinServiceTime: (state, payload) => {
      state.withinServiceTime = payload
    }
  },
  getters: {
    getShouldTakeControl: state => state.shouldTakeControl,
    getTheme: state => state.theme,
    getWithinServiceTime: state => state.withinServiceTime
  },
  actions: {
  }
}
