<template>
  <div class="card">
    <div class="card-divider">
      <h3>{{ service.folder.name }}: {{ service.name }}</h3>
    </div>
    <div class="card-section">
      <div class="grid-x grid-margin-x">
        <div v-if="seriesArtwork.hasOwnProperty('id')" class="cell shrink">
          <img
            :src="seriesArtwork.attributes.artwork_for_dashboard"
            :title="seriesArtwork.attributes.title"
            alt="Series Artwork"
          />
        </div>
        <div class="cell auto">
          <h4>{{ plan.attributes.title }}</h4>
          <h5>{{ plan.attributes.dates }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'service-title',
  props: {
    service: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
  },
  inject: ['urls', 'request'],
  data() {
    return {
      seriesArtwork: {},
    }
  },
  mounted() {},
  watch: {
    plan: {
      handler: 'getSeriesArtwork',
      immediate: true,
    },
  },
  computed: {},
  methods: {
    getSeriesArtwork: async function () {
      if (this.plan.relationships.series.data !== null) {
        try {
          const artwork = await this.request(
            `${this.urls.services}/series/${this.plan.relationships.series.data.id}`
          )
          this.seriesArtwork = artwork.response.data
        } catch (e) {
          console.error(e)
          this.seriesArtwork = {}
        }
      } else {
        this.seriesArtwork = {}
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
