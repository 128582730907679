import { render, staticRenderFns } from "./midi-commands-view.vue?vue&type=template&id=8ea47894&scoped=true&"
import script from "./midi-commands-view.vue?vue&type=script&lang=js&"
export * from "./midi-commands-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ea47894",
  null
  
)

export default component.exports