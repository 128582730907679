export default {
  state: () => ({
    isWithinService: false,
    selectedService: undefined
  }),
  mutations: {
    setIsWithinService: (state, payload) => {
      state.isWithinService = payload
    },
    setSelectedService: (state, payload) => {
      state.selectedService = payload
    }
  },
  getters: {
    // When does active service return undefined?
    getActiveService: (state, getters) => {
      // get the current service
      // if (getters.getServiceTimes === undefined) {
      //   return {
      //     id: ""
      //   };
      // }

      if (
        getters.getCurrentItemTime === undefined
        && getters.getNextItemTime === null
      ) {
        // get the last service
        return getters.getLastService
      }

      if (getters.getCurrentItemTime === undefined) {
        return undefined
      }

      // const current = getters.getServiceTimes.find(
      //   each => each.id === getters.getCurrentItemTime.relationships.plan_time.data.id
      // );
      // if (current === undefined)
      //   return undefined
      return getters.getServiceTimes.find(
        each => each.id === getters.getCurrentItemTime.relationships.plan_time.data.id
      )
    },
    getActiveServiceIndex: (state, getters) => {
      if (getters.getActiveService === undefined) {
        return -1
      }
      return getters.getServiceTimes.findIndex(
        each => each.id === getters.getActiveService.id
      )
    },
    /**
     * Gets the current item time from what is returned from the live data
     *
     * **Current Item time returns undefined only when at the end of a service
     * @return itemTime Object or undefined
     */
    getCurrentItemTime: (state, getters) => getters.getLiveIncluded.find(({ type }) => type === 'ItemTime'),
    /**
     * This just filters out all rehearsals and other plan times just so we look
     * at the service times.
     * @param  {[type]} state   [description]
     * @param  {[type]} getters [description]
     * @return {[type]}         [description]
     */
    getCurrentServices: (state, getters) => getters.getPlanTimes.filter(
      time => time.attributes.time_type === 'service'
    ),
    /**
     * Sometimes PCO doesn't send back the services in the correct order based
     * on time. This returns just the services sorted by the start time.
     * @param  {[type]} state   [description]
     * @param  {[type]} getters [description]
     * @return {[type]}         [description]
     */
    getServiceTimes: (state, getters) => getters.getCurrentServices.sort((a, b) => {
      const startDateA = new Date(a.attributes.starts_at)
      const startDateB = new Date(b.attributes.starts_at)
      if (startDateA < startDateB) {
        return -1
      }
      if (startDateA > startDateB) {
        return 1
      }
      return 0
    }),
    getSelectedService: (state, getters) => {
      if (state.selectedService === undefined) {
        return getters.getActiveService
      }
      return state.selectedService
    },
    getSelectedServiceRaw: state => state.selectedService,
    getSelectedServiceIndex: (state, getters) => getters.getServiceTimes.findIndex(
      each => each.id === getters.getSelectedService.id
    ),
    isWithinService: state => state.isWithinService,
    // itemsWithTimes: state => {isStart
    //   // for each itemTime
    //   for (const time of this.itemTimes) {
    //     // find the first item that matches the itemTime.
    //     let foundItem = this.items.find(
    //       each => each.id === time.relationships.item.data.id
    //     );
    //     // if we didn't find an item that matched the itemTime
    //     if (foundItem !== undefined) {
    //       // If the found item has plan_item_times
    //       if (foundItem.attributes.hasOwnProperty("plan_item_times")) {
    //         // add the itemTime to the item
    //         foundItem.attributes.plan_item_times.push(time);
    //       } else {
    //         // if not, then create the plan_item_times and make a new array with the itemTime
    //         Vue.set(foundItem.attributes, "plan_item_times", [time]);
    //         // foundItem.attributes.plan_item_times = [time];
    //       }
    //     }
    //   }
    //   return this.items;
    // },
    /**
     * This organizes all the itemTimes with each item so we can easily find
     * the correct itemTime for each service. This also filters out any old
     * itemTimes.
     * @param  {[type]} state   [description]
     * @param  {[type]} getters [description]
     * @return {[type]}         [description]
     */
    getItemListWithTimes: (state, getters) => {
      const itemListWithTimes = getters.getItems.map(item => ({
        id: item.id,
        itemTimes: []
      }))
      // for each itemTime
      for (const time of getters.getItemTimes) {
        // find the first item that matches the itemTime.
        const foundItem = itemListWithTimes.find(
          each => each.id === time.relationships.item.data.id
        )
        // if we didn't find an item that matched the itemTime
        if (foundItem !== undefined) {
          // check to see if an item for the same planTime is already in the list
          const duplicate = foundItem.itemTimes.findIndex(
            each => each.relationships.plan_time.data.id
              === time.relationships.plan_time.data.id
          )
          if (duplicate !== -1) {
            // compare the ids. The one with the higher id wins because it is newer
            if (time.id > foundItem.itemTimes[duplicate].id) {
              foundItem.itemTimes.splice(duplicate, 1)
              // add the itemTime to the item
              foundItem.itemTimes.push(time)
            }
          } else {
            // add the itemTime to the item
            foundItem.itemTimes.push(time)
          }
        }
      }
      return itemListWithTimes
    },
    getLastService: (state, getters) => {
      if (getters.getServiceTimes.length > 0) {
        return getters.getServiceTimes[getters.getServiceTimes.length - 1]
      }
      return {}
    },
    getNextItemTime: (state, getters) => {
      try {
        if (getters.getLive.relationships.next_item_time.data === null) {
          return null
        }
        return getters.getLive.relationships.next_item_time.data.id
      } catch (error) {
        return undefined
      }
    },
    isStart: (state, getters) => {
      // if it's the end of service then it's not the start
      if (getters.getCurrentItemTime === undefined) {
        return false
      }
      // check to see if the current itemTime is the same as the service
      if (getters.getCurrentItemTime.id === getters.getServiceTimes[0].id) {
        return true
      }
      return false

      // if (getters.getCurrentItemTime.relationships.item.data === null) {
      //   return true
      // }
      //
      // if (getters.getActiveServiceIndex !== 0) {
      //   return false
      // }
    },
    isEnd: (state, getters) => getters.getCurrentItemTime === undefined
      && getters.getNextItemTime === null,
    getActiveServiceItemTimes: (state, getters) => {
      if (getters.getActiveService === undefined) {
        return []
      }
      return getters.getItemTimes
        .filter(
          each => each.relationships.plan_time.data.id === getters.getActiveService.id
        )
        .filter(each => !each.attributes.exclude)
    },
    getActiveServiceItemTimesUnique: (state, getters) => getters.getActiveServiceItemTimes
      .reverse()
      .reduce((accum, current) => {
        if (
          accum.findIndex(
            i => i.relationships.item.data.id
                === current.relationships.item.data.id
          ) < 0
        ) {
          accum.push(current)
        }
        return accum
      }, [])
      .reverse(),
    // getItemTimesOrdered: (state, getters) => {
    //   const itemsPerService = getters.getServiceTimes.map(service => dispatch('getServiceItemTimes', { service }));
    //   return itemsPerService
    //     .map((each, index) => {
    //       each.unshift({
    //         type: "ItemTime",
    //         id: getters.getServiceTimes[index].id,
    //         relationships: {
    //           item: { data: null },
    //           plan_time: {
    //             data: { type: "PlanTime", id: getters.getServiceTimes[index].id }
    //           }
    //         }
    //       });
    //       return each;
    //     })
    //     .flat()
    // },
    getCurrentIndex: (state, getters) => {
      // get the current position
      let currentIndex
      if (getters.isEnd) {
        // at the end of service
        currentIndex = getters.getActiveServiceItemTimesUnique.length
      } else if (getters.isStart) {
        // at the beginning of service
        currentIndex = -1
      } else {
        currentIndex = getters.getActiveServiceItemTimesUnique.findIndex(
          itemTime => itemTime.relationships.item.data.id
            === getters.getCurrentItemTime.relationships.item.data.id
        )
      }
      return {
        index: currentIndex,
        length: getters.getActiveServiceItemTimesUnique.length
      }
    }
  },
  actions: {
    // To be used for only for the midi action to make sure that it's not dependant on the web UI
    goToItem: async ({ getters, dispatch }, id) => {
      // get the current position
      const currentIndex = getters.getCurrentIndex.index

      // find the position that you are trying to jump
      const goToIndex = getters.getActiveServiceItemTimesUnique.findIndex(
        itemTime => itemTime.relationships.item.data.id === id
      )
      dispatch('goToIndex', {
        currentIndex,
        goToIndex
      })
      // this.goToIndex(currentIndex, goToIndex);
    },
    goToItemSelected: async ({ getters, dispatch }, id) => {
      // get the full list
      const itemsPerServicePromise = getters.getServiceTimes.map(service => dispatch('getServiceItemTimes', { service }))
      let itemsPerService
      try {
        itemsPerService = await Promise.all(itemsPerServicePromise)
      } catch (e) {
        console.log(e)
        throw e
      }
      const allServiceItemTimes = itemsPerService
        .map((each, index) => {
          each.unshift({
            type: 'ItemTime',
            id: getters.getServiceTimes[index].id,
            relationships: {
              item: { data: null },
              plan_time: {
                data: {
                  type: 'PlanTime',
                  id: getters.getServiceTimes[index].id
                }
              }
            }
          })
          return each
        })
        .flat()

      // find the index of the item
      let pos1Index = 0
      if (getters.isEnd) {
        const itemTimesOrdered = await dispatch('getItemTimesOrdered')
        pos1Index = itemTimesOrdered.length
      } else {
        pos1Index = allServiceItemTimes.findIndex(
          each => each.id === getters.getCurrentItemTime.id
        )
      }
      console.log(`id: ${id}`)

      const pos2Index = allServiceItemTimes.findIndex(each => {
        if (each.relationships.item.data === null) {
          if (each.relationships.plan_time.data.id === id) {
            return true
          }
          return false
        }
        if (
          each.relationships.plan_time.data.id
            === getters.getSelectedService.id
          && each.relationships.item.data.id === id
        ) {
          return true
        }
        return false
      })
      console.log(`pos1Index: ${pos1Index}`)
      console.log(`pos2Index: ${pos2Index}`)
      dispatch('goToIndex', {
        currentIndex: pos1Index,
        goToIndex: pos2Index
      })
      // this.goToIndex(pos1Index, pos2Index);
    },
    goToIndex: async ({ dispatch }, { currentIndex, goToIndex }) => {
      // find the position that you are trying to jump
      console.log(`currentIndex: ${currentIndex}`)
      console.log(`goToIndex: ${goToIndex}`)
      let move = 0
      let direction
      // let movePromise
      if (currentIndex < goToIndex) {
        move = goToIndex - currentIndex
        direction = 'next'
        // eslint-disable-next-line no-unused-vars
        for (const i of [...Array(move).keys()]) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch('goToNextItem', true)
        }
        // movePromise = [...Array(move).keys()].map(each => this.goToNextItem(true))
      } else {
        move = currentIndex - goToIndex
        direction = 'previous'
        // eslint-disable-next-line no-unused-vars
        for (const i of [...Array(move).keys()]) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch('goToPreviousItem', true)
        }
        // movePromise = [...Array(move).keys()].map(each => this.goToPreviousItem(true))
      }
      console.log(`move: ${move}`)
      console.log(`direction: ${direction}`)
    },
    /**
     * This gets all the item times by service then filters out the excluded
     * items.
     * @param  {[type]} getters [description]
     * @param  {[type]} service [description]
     * @return {[type]}         [description]
     */
    getServiceItemTimes: ({ getters }, { service }) => getters.getItemTimes
      .filter(each => each.relationships.plan_time.data.id === service.id)
      .filter(each => !each.attributes.exclude)
      .reverse()
      .reduce((accum, current) => {
        if (
          accum.findIndex(
            i => i.relationships.item.data.id
                === current.relationships.item.data.id
          ) < 0
        ) {
          accum.push(current)
        }
        return accum
      }, [])
      .reverse(),
    isWithinService: ({ getters, commit }) => {
      const withinTime = getters.getWithinServiceTime
      // get the current service plan id
      // get the plan times
      // filter the times for services
      const planTimes = getters.getServiceTimes
      // if the current time is within 15 minutes of 1 of the services
      const currentDate = new Date()
      const time = planTimes.filter(tempTime => {
        const dateStart = new Date(tempTime.attributes.starts_at)
        // remove 15 minutes
        dateStart.setMinutes(dateStart.getMinutes() - withinTime)
        const dateEnd = new Date(tempTime.attributes.ends_at)
        // add 15 minutes
        dateEnd.setMinutes(dateEnd.getMinutes() + withinTime)
        // console.log(`start: ${dateStart}`);
        // console.log(`end: ${dateEnd}`);
        // console.log(`current: ${currentDate}`);
        if (currentDate >= dateStart && currentDate <= dateEnd) {
          // commit("setIsWithinService", true)
          return true
        }
        return false
        // commit("setIsWithinService", false)
      })
      if (time.length > 0) {
        commit('setIsWithinService', true)
      } else {
        commit('setIsWithinService', false)
      }
    },
    // isCurrent: ({ state }, payload) => {
    //   // get the item
    //   const item = store.itemsWithTimes.find(tempItem => tempItem.id === payload)

    //   // find the item time associated with the selected plan_time
    //   const time = item.itemTimes.find(each => each.relationships.plan_time.data.id === state.selectedService.id)
    //   return state.currentItemTime.id === time.id
    //   // try {
    //   //   // console.log(`${this.currentTime.relationships.item.data.id} ==? ${id}`);
    //   //   // return this.currentTime.itemTime.relationships.item.data.id === this.item.id
    //   //   // find the item time associated with the selected plan_time
    //   //   const time = this.item.attributes.plan_item_times.find(
    //   //     each =>
    //   //       each.relationships.plan_time.data.id === this.selectedService.id
    //   //   );
    //   //   return this.currentTime.itemTime.id === time.id;
    //   // } catch (e) {
    //   //   return false;
    //   // }
    // },
    /**
     * Gets all the itemsTimes for all the services and puts them in order based
     * on the service. This returns a master list of all the item times that the
     * user could ever set for the plan. This also adds a plan time for each
     * service so that it takes them into account. This function is helpful for
     * when you have to move around in the service. This essentially creates a
     * map of the service and then you can traverse by following the map.
     * @param  {[type]}  getters  [description]
     * @param  {[type]}  dispatch [description]
     * @return {Promise}          [description]
     */
    getItemTimesOrdered: async ({ getters, dispatch }) => {
      const itemsPerServicePromise = getters.getServiceTimes.map(service => dispatch('getServiceItemTimes', { service }))
      const itemsPerService = await Promise.all(itemsPerServicePromise)
      return itemsPerService
        .map((each, index) => {
          each.unshift({
            type: 'ItemTime',
            id: getters.getServiceTimes[index].id,
            relationships: {
              item: { data: null },
              plan_time: {
                data: {
                  type: 'PlanTime',
                  id: getters.getServiceTimes[index].id
                }
              }
            }
          })
          return each
        })
        .flat()
    }
    // isExcluded: function() {
    //   // filter the itemTimes for the current service
    //   // const time = this.item.attributes.plan_item_times.find(each => each.relationships.plan_time.data.id === this.activeService.id)
    //   if (this.item.attributes.plan_item_times === undefined) {
    //     return false;
    //   }
    //   const time = this.item.attributes.plan_item_times.find(
    //     each => each.relationships.plan_time.data.id === this.selectedService.id
    //   );
    //   if (time !== undefined) {
    //     return time.attributes.exclude;
    //   } else {
    //     return false;
    //   }
    // }
  }
}
