<template lang="html">
    <div class="grid-x align-center">
        <div class="cell large-4 medium-6 position-fixed">
            <transition name="fade">
                <div v-if="error.message !== ''" class="callout alert ">
                    <h5>{{error.name}}</h5>
                    <p>{{error.message}}</p>
                    <button @click="reset()" class="close-button" aria-label="Dismiss Error" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
  name: 'error-box',
  props: {},
  data() {
    return {}
  },
  computed: {
    error: function () {
      return this.$store.getters.getError
    },
  },
  methods: {
    reset: function () {
      this.$store.commit('setError', Error(''))
    },
  },
}
</script>

<style lang="css" scoped>
</style>
