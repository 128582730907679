<template>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-9">
      <label for="withinServiceTimeSetting" class="middle"
        >Within Service Buffer</label
      >
    </div>
    <div class="cell medium-2 small-3">
      <input
        type="number"
        id="withinServiceTimeSetting"
        name="withinServiceTimeSetting"
        v-model="withinServiceTime"
      />
    </div>
    <div class="cell medium-7">
      <label
        >This is the time (in minutes) before and after service that midi
        actions will work. So midi actions shouldn't be triggered during
        practice.</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-within-service',
  data() {
    return {}
  },
  mounted() {},
  computed: {
    withinServiceTime: {
      get: function () {
        // check local storage
        return this.$store.getters.getWithinServiceTime
      },
      set: function (newValue) {
        return this.$store.commit('setWithinServiceTime', newValue)
      },
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
