<template>
  <div v-if="this.selectedService !== undefined" class="card-section">
    <div class="grid-x grid-margin-x">
      <div class="cell medium-9">
        <h3>
          <span class="selected-service-date">{{ getStartDateText() }}</span>
          <dropdown
            v-if="serviceTimes.length > 1"
            button-title="Show All Services"
          >
            <ul class="menu">
              <li
                v-for="(service, index) in serviceTimes"
                :key="service.id"
                :class="service.id === selectedService.id ? 'is-active' : ''"
              >
                <a
                  @click="switchToService(service)"
                  :title="serviceDropdownTitle(service)"
                >
                  <span class="service-select-title"
                    >{{ index + 1 }}: {{ getShortDate(service.attributes.starts_at) }}</span
                  >
                  <span
                    v-if="service.id === activeService.id"
                    class="success label"
                    >Current</span
                  >
                </a>
              </li>
            </ul>
          </dropdown>
        </h3>
        <p>
          <span class="scheduled-end"
            >Scheduled End: {{ getEndDateText() }}</span
          >
          <br />
          <template v-if="selectedService.attributes.live_ends_at !== null">
            <span class="live-end">Live End Time: {{ shortLiveEndDate }}</span>
          </template>
        </p>
      </div>
      <div class="cell medium-3">
        <button
          type="button"
          class="button small expanded"
          @click="goToStart"
          :disabled="disableStart"
          :title="startButtonTitle"
        >
          Go to Start
        </button>
        <button
          type="button"
          class="button small expanded"
          @click="goToEnd"
          :disabled="disableEnd"
          :title="endButtonTitle"
        >
          Go to End
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from './dropdown.vue'

export default {
  name: 'service-picker',
  components: {
    Dropdown,
  },
  props: {},
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapGetters({
      activeService: 'getActiveService',
      currentItemTime: 'getCurrentItemTime',
      hasControl: 'hasControl',
      isOffline: 'isOffline',
      selectedService: 'getSelectedService',
      selectedServiceIndex: 'getSelectedServiceIndex',
      serviceTimes: 'getServiceTimes',
      shouldAutoUpdate: 'shouldAutoUpdate',
      itemListWithTimes: 'getItemListWithTimes',
      nextItemTime: 'getNextItemTime',
      isEnd: 'isEnd',
    }),
    disableStart: function () {
      return (
        this.isOffline
        || !this.hasControl
        || this.isStartOfService
        || !this.shouldAutoUpdate
      )
    },
    disableEnd: function () {
      return (
        this.isOffline
        || this.isEndOfService
        // this.isEnd ||
        || !this.hasControl
        || !this.shouldAutoUpdate
      )
    },
    startButtonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.hasControl) {
        return "You don't have control"
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.isStartOfService) {
        return 'Already at the Start'
      }
      return 'Move to the Start of this Service (Resets Service Position)'
    },
    endButtonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.hasControl) {
        return "You don't have control"
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.isEndOfService) {
        return 'Already at the End of the Service'
      }
      return 'Move to the End of this Service (Moves to Next Service or Finishes the Service)'
    },
    isStartOfService: function () {
      // if the services are over then it's definitely not the start
      if (this.currentItemTime === undefined) {
        return false
      }
      // try {
      if (
        this.currentItemTime.relationships.plan_time.data.id
          === this.selectedService.id
        && this.currentItemTime.relationships.item.data === null
      ) {
        return true
      }
      return false
      // } catch (error) {
      //   return false;
      // }
    },
    isEndOfService: function () {
      // check to see if the service selected is the last service
      if (this.selectedServiceIndex + 1 === this.serviceTimes.length) {
        if (this.nextItemTime === null && this.currentItemTime === undefined) {
          // This is the end of all services
          return true
        }
        return false
      }

      // if the service selected is another service but the service is over
      if (this.currentItemTime === undefined) {
        return false
      }

      // if the end of the selected service is the start of the current service
      if (
        this.currentItemTime.id
        === this.serviceTimes[this.selectedServiceIndex + 1].id
      ) {
        return true
      }
      return false
    },
    shortLiveEndDate: function () {
      return this.getShortDate(this.selectedService.attributes.live_ends_at)
    },
    // startDate: function () {
    //   if (this.activeService !== undefined) {
    //     return this.getDate(this.activeService.attributes.starts_at)
    //   } else {
    //     return ""
    //   }
    // },
    // endDate: function () {
    //   if (this.activeService !== undefined) {
    //     return this.getDate(this.activeService.attributes.ends_at)
    //   } else {
    //     return ""
    //   }
    // },
    // shortDateTime: function () {
    //   if (this.activeService !== undefined) {
    //     return this.getShortDate(this.activeService.attributes.starts_at)
    //   } else {
    //     return ""
    //   }
    // }
  },
  methods: {
    serviceDropdownTitle: function (service) {
      if (service.id === this.selectedService.id) {
        return `${this.getFullDate(service.attributes.starts_at)}
Selected Service`
      }
      return `${this.getFullDate(service.attributes.starts_at)}
Click to View the Items in this Service`
    },
    getStartDateText: function () {
      return this.getShortDate(this.selectedService.attributes.starts_at)
    },
    getEndDateText: function () {
      return this.getShortDate(this.selectedService.attributes.ends_at)
    },
    getShortDate: function (date) {
      const dateObject = new Date(date)

      const shortDate = dateObject.toLocaleString('en-US', {
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
      })
      return `${shortDate}`
    },
    getFullDate: function (date) {
      const dateObject = new Date(date)
      return dateObject.toLocaleString('en-US', {
        dateStyle: 'full',
        timeStyle: 'short',
      })
    },
    goToStart: function () {
      this.$emit('go-to-start', this.selectedService)
    },
    goToEnd: function () {
      this.$emit('go-to-end', this.selectedService)
    },
    switchToService: function (service) {
      if (service.id === this.activeService.id) {
        this.$emit('selected-service', undefined)
      } else {
        this.$emit('selected-service', service)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu {
  display: block;
}
</style>
