<template>
  <tr>
    <td>
      <span class="midi-title">
        {{ midiCommand.title
        }}<template
          v-if="
            midiCommand.hasOwnProperty('options') &&
            midiCommand.options.hasOwnProperty('item') &&
            midiCommand.options.item !== '' &&
            !editor
          "
          >: {{ midiCommand.options.item }}</template
        >
      </span>
      <template v-if="midiCommand.hasOwnProperty('options') && editor">
        <label v-if="midiCommand.action === 4">
          Plan Item
          <select
            class
            name
            v-model="midiCommand.options.item"
            @change="saveMidiCommand"
          >
            <template v-if="!hasItem">
              <optgroup label="Saved Item:">
                <option :value="original.options.item">
                  {{ original.options.item }}
                </option>
              </optgroup>
            </template>
            <template v-for="section in computedItems">
              <optgroup
                :key="section.header.id"
                v-if="section.header.hasOwnProperty('type')"
                :label="section.header.attributes.title"
              >
                <option
                  v-for="item in section.items"
                  :key="item.id"
                  :value="item.attributes.title"
                >
                  {{ item.attributes.title }}
                </option>
              </optgroup>
              <optgroup v-else label="Plan Items" :key="section.header.id">
                <option
                  v-for="item in section.items"
                  :key="item.id"
                  :value="item.attributes.title"
                >
                  {{ item.attributes.title }}
                </option>
              </optgroup>
            </template>
          </select>
        </label>
      </template>
    </td>
    <td>C-2 (0)</td>
    <td v-if="!editor">{{ midiCommand.note }}</td>
    <td v-else>
      <select class name v-model="midiCommand.note" @change="saveMidiCommand">
        <option
          v-for="option in midiNotes"
          :key="option"
          :value="option"
          :disabled="hasMidi(option)"
        >
          {{ option }}
        </option>
      </select>
    </td>
    <td v-if="editor">
      <button @click="deleteMidiCommand" class="alert small button">
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'settings-midi-command',
  props: {
    midiCommand: {
      type: Object,
      required: true,
    },
    editor: {
      type: Boolean,
      default: false,
    },
    computedItems: {
      type: Array,
      required: true,
    },
    midiNotes: {
      type: Array,
      required: true,
    },
    hasMidi: {
      type: Function,
      required: true,
    },
  },
  //   "midiCommand",
  //   "editor",
  //   "computedItems",
  //   "midiNotes",
  //   "hasMidi"
  // ],
  data() {
    // let original
    // if (this.midiCommand.hasOwnProperty('options')) {
    //   original = this.midiCommand.options.title
    // }
    return {
      original: JSON.parse(JSON.stringify(this.midiCommand)),
    }
  },
  computed: {
    items: function () {
      return this.$store.getters.getItems
    },
    hasItem: function () {
      if (this.items !== undefined) {
        return (
          this.items.findIndex(
            // eslint-disable-next-line eqeqeq
            (each) => each.attributes.title == this.original.options.item
          ) !== -1
        )
      }
      return false
    },
  },
  methods: {
    deleteMidiCommand: function () {
      this.$store.dispatch('removeMidiCommand', this.midiCommand.id)
      // this.$emit("delete-midi-command", this.midiCommand.id);
    },
    saveMidiCommand: function () {
      this.$store.dispatch('updateMidiCommand', this.midiCommand)
      // this.$emit("save-midi-command", this.midiCommand);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
