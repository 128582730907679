<template>
  <thead>
    <tr>
      <th colspan="3">{{header.attributes.title}}</th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'item-section-header',
  props: {
    header: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
