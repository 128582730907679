<template>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-9">
      <label class="middle">Theme</label>
    </div>
    <div class="cell medium-2 small-3">
      <fieldset>
        <template v-for="each in themes">
          <label :key="each.name">
            <input
              type="radio"
              name="settings-theme"
              :value="each.name"
              :id="getID(each.name)"
              v-model="theme"
              @change="loadTheme"
            />
            {{ each.name }}
          </label>
        </template>
      </fieldset>
    </div>
    <div class="cell medium-7"></div>
  </div>
</template>

<script>
export default {
  name: 'settings-theme',
  data() {
    return {
      themes: [
        {
          name: 'System',
          variables: [],
        },
        {
          name: 'Light',
          variables: [
            {
              name: 'bg-color',
              value: '#fefefe',
            },
            {
              name: 'text-color',
              value: '#0a0a0a',
            },
            {
              name: 'white-text',
              value: '#0a0a0a',
            },
            {
              name: 'secondary',
              value: '#f8f8f8',
            },
            {
              name: 'secondary-color',
              value: '#0a0a0a',
            },
            {
              name: 'secondary-callout',
              value: '#eaeaea',
            },
            {
              name: 'selected-color',
              value: '#cacaca',
            },
            {
              name: 'blue',
              value: '#1779ba',
            },
            {
              name: 'brightness',
              value: null,
            },
            {
              name: 'border',
              value: '#e6e6e6',
            },
            {
              name: 'accordion-text',
              value: '#1779ba',
            },
            {
              name: 'secondary-callout-default',
              value: '#eaeaea',
            },
            {
              name: 'green',
              value: '#e1faea',
            },
            {
              name: 'green-border',
              value: '#13C25C',
            },
            {
              name: 'red',
              value: '#cc4b37',
            },
          ],
        },
        {
          name: 'Dark',
          variables: [
            {
              name: 'bg-color',
              value: '#121212',
            },
            {
              name: 'text-color',
              value: '#a5a5a5',
            },
            {
              name: 'white-text',
              value: 'white',
            },
            {
              name: 'secondary',
              value: '#181818',
            },
            {
              name: 'secondary-color',
              value: '#a5a5a5',
            },
            {
              name: 'secondary-callout',
              value: '#888888',
            },
            {
              name: 'selected-color',
              value: '#1e1e1e',
            },
            {
              name: 'blue',
              value: '#0d486f',
            },
            {
              name: 'brightness',
              value: '.7',
            },
            {
              name: 'border',
              value: '#1e1e1e',
            },
            {
              name: 'accordion-text',
              value: '#fff',
            },
            {
              name: 'secondary-callout-default',
              value: '#242424',
            },
            {
              name: 'green',
              value: '#0B7437',
            },
            {
              name: 'green-border',
              value: '#13C25C',
            },
            {
              name: 'red',
              value: '#7a2d21',
            },
          ],
        },
      ],
      settingsID: 'settings-theme',
    }
  },
  mounted() {},
  computed: {
    theme: {
      get: function () {
        return this.$store.getters.getTheme
      },
      set: function (newValue) {
        this.$store.commit('setTheme', newValue)
      },
    },
  },
  methods: {
    getID: function (theme) {
      return `${this.settingsID}-${theme}`
    },
    loadTheme: function () {
      console.log(`Theme: ${this.theme}`)

      if (this.theme === 'System') {
        if (
          window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          // dark mode
          this.applyTheme('Dark')
        } else {
          this.applyTheme('Light')
        }
      } else {
        this.applyTheme(this.theme)
      }
    },
    applyTheme: function (themeName) {
      const foundTheme = this.themes.find((each) => each.name === themeName)

      if (foundTheme) {
        foundTheme.variables.forEach((each) => {
          document.documentElement.style.setProperty(
            `--${each.name}`,
            each.value
          )
        })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
