<template>
  <div>
    <h3 class="separator-center">Midi Settings</h3>
    <table v-if="canMidi">
      <thead>
        <th>Command</th>
        <th>Midi Note</th>
        <th>Midi Intensity</th>
        <template name="expand" is="transition">
          <th v-if="editor">Edit</th>
        </template>
      </thead>
      <tbody>
        <settings-midi-command
          v-for="midiCommand in midiCommands"
          :key="midiCommand.id"
          :midi-command="midiCommand"
          :editor="editor"
          :computed-items="computedItems"
          :midi-notes="midiNotes"
          :has-midi="hasMidi"
        ></settings-midi-command>
      </tbody>
    </table>
    <transition name="expand">
      <form v-if="add" class @submit.prevent="addMidiCommand">
        <div class="grid-container">
          <div class="grid-x grid-padding-x">
            <div class="cell medium-12">
              <h5>New Midi Command</h5>
            </div>
            <div class="cell text-left medium-9">
              <label>
                Action
                <select class="midi-actions" name v-model="midiNew.action">
                  <option
                    v-for="option in midiActions"
                    :key="option.value"
                    :value="option.value"
                    :disabled="hasItems(option)"
                  >{{option.title}}</option>
                </select>
              </label>
              <label v-if="midiNew.action === 4">
                Plan Item
                <select class="plan-items" name v-model="midiNew.options.item">
                  <template v-for="section in computedItems">
                    <optgroup
                      :key="section.header.id"
                      v-if="section.header.hasOwnProperty('type')"
                      :label="section.header.attributes.title"
                    >
                      <option
                        v-for="item in section.items"
                        :key="item.id"
                        :value="item.attributes.title"
                      >{{item.attributes.title}}</option>
                    </optgroup>
                    <optgroup v-else label="Plan Items" :key="section.header.id">
                      <option
                        v-for="item in section.items"
                        :key="item.id"
                        :value="item.attributes.title"
                      >{{item.attributes.title}}</option>
                    </optgroup>
                  </template>
                </select>
              </label>

              <!-- <fieldset class="">
  <legend>Save Settings</legend>
  <input type="radio" name="pokemon" value="Red" id="pokemonRed" required><label for="pokemonRed">Only for this service</label>
  <input type="radio" name="pokemon" value="Blue" id="pokemonBlue"><label for="pokemonBlue">Always</label>
              </fieldset>-->
              <div class="button-group">
                <input :disabled="specificItemCheck" type="submit" class="button" name value="Add" />
                <!-- <button @click="addMidiCommand()" type="button" class="button" name="button">Add</button> -->
                <button
                  @click="addMidiCommand(false)"
                  type="button"
                  class="button secondary"
                  name="button"
                >Cancel</button>
              </div>
            </div>
            <div class="cell text-left medium-3">
              <label>
                Midi Intensity
                <select class name v-model="midiNew.note">
                  <option
                    v-for="option in midiNotes"
                    :key="option"
                    :value="option"
                    :disabled="hasMidi(option)"
                  >{{option}}</option>
                </select>
              </label>
            </div>
          </div>
        </div>
      </form>
    </transition>
    <div v-if="canMidi" class="grid-x">
      <div class="cell small-6 align-left">
        <div class="button-group">
          <transition name="fade">
            <button
              v-if="!add"
              @click="midiSetNextOpenIntensity"
              type="button"
              class="button"
              name="button"
            >Add Midi Command</button>
          </transition>
          <transition name="fade">
            <button
              v-if="!add && !editor"
              @click="editor=true"
              type="button"
              class="button"
              name="button"
            >Edit</button>
            <button v-else-if="!add && editor" @click="editor=false" type="button" class="button" name="button">Done</button>
          </transition>
        </div>
      </div>
      <div class="cell small-6 align-right">
        <button
          @click="showImportExport = !showImportExport"
          type="button"
          class="button small float-right"
          name="button"
        >Import/Export</button>
        <button
          @click="setDefaultMidiCommands"
          type="button"
          class="button small float-right"
          name="button"
        >Default</button>
      </div>
      <transition name="expand">
        <div v-if="showImportExport" class="cell medium-12">
          <h5>Import/Export Commands</h5>
          <button
            @click="importMidiCommands(true)"
            type="button"
            class="button small float-right"
            name="button"
          >Clear and Import</button>
          <button
            @click="importMidiCommands"
            type="button"
            class="button small float-right"
            name="button"
          >Import</button>
          <label>
            Import
            <textarea placeholder="Paste data here" v-model="importMidiJSON"></textarea>
          </label>
          <label>
            Export
            <textarea :value="exportMidiJSON"></textarea>
          </label>
        </div>
      </transition>
    </div>
    <h6 v-else>Midi is not supported in this browser.</h6>
  </div>
</template>

<script>
import SettingsMidiCommand from './settings-midi-command.vue'
// import { openDB, deleteDB, wrap, unwrap } from 'idb'

export default {
  name: 'settings-midi',
  props: {
    computedItems: {
      type: Array,
      default: () => []
    },
    canMidi: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SettingsMidiCommand
  },
  data() {
    return {
      editor: false,
      add: false,
      midiActions: [
        {
          title: 'Go to Next Item',
          value: 0,
          // run: function(vueThis) {
          //   vueThis.goToNextItem();
          // }
        },
        {
          title: 'Go to Previous Item',
          value: 1,
          // run: function(vueThis) {
          //   vueThis.goToPreviousItem();
          // }
        },
        {
          title: 'Reset Current Service',
          value: 2,
          // run: function(vueThis) {
          //   vueThis.resetCurrentService();
          // }
        },
        {
          title: 'Go to End of Service',
          value: 3,
          // run: function(vueThis) {
          //   vueThis.goToEnd();
          // }
        },
        {
          title: 'Go to Specific Item',
          value: 4,
          // run: function(vueThis, item) {
          //   //find the item
          //   let found = vueThis.allItems.find(
          //     each => each.attributes.title === item
          //   );
          //   // console.log(found);
          //   if (found !== undefined) {
          //     vueThis.goToItem(found.id);
          //   }
          // }
        },
        {
          title: 'Go Forward 2 Times',
          value: 5,
          // run: function(vueThis) {
          //   vueThis.goToNextItem().then(function(next) {
          //     vueThis.goToNextItem();
          //   });
          // }
        },
        {
          title: 'Go Forward 3 Times',
          value: 6,
          // run: function() {
          //   this.goToNextItem().then(function(next) {
          //     this.goToNextItem().then(function(next) {
          //       this.goToNextItem();
          //     });
          //   });
          // }
        }
      ],
      // midiCommands: [],
      midiNew: {
        title: '',
        action: 0,
        options: {
          item: ''
        },
        note: ''
      },
      midiNotes: [...Array(128).keys()],
      dbName: 'PCO-Live-Midi',
      dbVersion: 1,
      dbMidiActionName: 'midiActions',
      importMidiJSON: '',
      showImportExport: false
    }
  },
  mounted() {
    // this.loadMidiCommands();
  },
  computed: {
    midiCommands: function () {
      return this.$store.getters.getMidiCommands
    },
    specificItemCheck: function () {
      return this.midiNew.action === 4 && this.midiNew.options.item === ''
    },
    exportMidiJSON: function () {
      return JSON.stringify(this.$store.getters.getMidiCommands)
    }
  },
  methods: {
    // loadMidiCommands: async function() {
    //   try {
    //     await this.$store.dispatch("loadMidiCommands")
    //     // this.midiCommands = await this.dbGetMidiCommands();
    //     // if (this.midiCommands.length === 0) {
    //     //   // set default settings
    //     //   this.setDefaultMidiCommands();
    //     // }
    //   } catch (e) {
    //     console.log(e);
    //     this.error = e;
    //   }
    // },
    hasItems: function(action) {
      return action.value === 4 && this.computedItems.length === 0
      // if (action.value === 4) {
      //   if (this.computedItems.length === 0) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    hasMidi: function(note) {
      // eslint-disable-next-line eqeqeq
      return this.midiCommands.findIndex(item => item.note == note) !== -1
      // if (this.midiCommands.findIndex(item => item.note == note) === -1) {
      //   return false;
      // } else {
      //   return true;
      // }
    },
    setDefaultMidiCommands: async function() {
      await this.$store.dispatch('setDefaultMidiCommands')
    },
    importMidiCommands: async function(clear = false) {
      // Show a dialog to imput text
      // then show a button to import
      let data
      try {
        data = JSON.parse(this.importMidiJSON)
      } catch (e) {
        console.error(e)
        return false
      }
      data = data.map(each => {
        delete each.id
        return each
      })
      try {
        if (clear) {
          await this.$store.dispatch("removeAllMidiCommands")
        }
        
        await this.$store.dispatch('importMidiCommands', data)
        this.$emit('update')
        this.importMidiJSON = ""
      } catch (error) {
        console.error(error)
        this.error = error
      }
     
      return true
    },
    addMidiCommand: async function(state = true) {
      this.add = false
      this.midiNew.title = this.midiActions[this.midiNew.action].title
      if (state) {
        try {
          await this.$store.dispatch('addMidiCommand', this.midiNew)
          // this.loadMidiCommands();
        } catch (e) {
          // Don't reset the form on error
          console.error(e)
          return false
        }
      }
      this.midiNew = {
        title: '',
        action: 0,
        options: {
          item: ''
        },
        note: ''
      }
      return true
    },
    // deleteMidiCommand: function(id) {
    //   // get the index of the command
    //   // let removed = this.midiCommands.splice(index, 1)
    //   //remove from the database
    //   // this.dbRemoveMidiCommand(id);
    //   // // update the list
    //   // this.loadMidiCommands();
    //   this.$state.dispatch("removeMidiCommand", id)
    // },
    // saveMidiCommand: function(command) {
    //   this.$store.dispatch("updateMidiCommand", command)
    //   // this.dbUpdateMidiCommand(command);
    // },
    // updateMidiCommands: async function() {
    //   const current = JSON.stringify(this.midiCommands);
    //   let old = await this.$store.dispatch("loadMidiCommands")
    //   if (current === JSON.stringify(old)) {
    //     this.editor = false;
    //   } else {
    //     // update DB
    //     let vueThis = this;
    //     let updatePromises = this.midiCommands.map(command => {
    //       return vueThis.dbUpdateMidiCommand(command);
    //     });
    //     try {
    //       await Promise.all(updatePromises);
    //       this.editor = false;
    //     } catch (e) {
    //       this.error = e;
    //     }
    //   }
    // },
    midiSetNextOpenIntensity: function() {
      const vueThis = this
      const firstNote = this.midiNotes.findIndex(note => {
        // eslint-disable-next-line eqeqeq
        const index = vueThis.midiCommands.findIndex(each => each.note == note)
        return index < 0
      })
      // If there are free intensities available
      if (firstNote !== -1) {
        this.midiNew.note = firstNote
      }
      this.add = true
      this.editor = false
    },
    // dbAddMidiCommand: async function(data) {
    //   let vueThis = this;
    //   const db = await openDB(this.dbName, this.dbVersion, {
    //     upgradeDB(db) {
    //       db.createObjectStore(vueThis.dbStoreName, {
    //         keyPath: "id"
    //       });
    //       db.createObjectStore(vueThis.dbMidiActionName, {
    //         keyPath: "id",
    //         autoIncrement: true
    //       });
    //     }
    //   });
    //
    //   await db.add(this.dbMidiActionName, data);
    // },
    // dbUpdateMidiCommand: async function(command) {
    //   let vueThis = this;
    //   const db = await openDB(this.dbName, this.dbVersion, {
    //     upgradeDB(db) {
    //       db.createObjectStore(vueThis.dbStoreName, {
    //         keyPath: "id"
    //       });
    //       db.createObjectStore(vueThis.dbMidiActionName, {
    //         keyPath: "id",
    //         autoIncrement: true
    //       });
    //     }
    //   });
    //   await db.put(this.dbMidiActionName, command);
    // },
    // dbGetMidiCommands: async function() {
    //   let vueThis = this;
    //   let db;
    //   try {
    //     db = await openDB(vueThis.dbName, vueThis.dbVersion, {
    //       upgrade(db) {
    //         const store = db.createObjectStore(vueThis.dbStoreName, {
    //           keyPath: "id"
    //         });
    //         db.createObjectStore(vueThis.dbMidiActionName, {
    //           keyPath: "id",
    //           autoIncrement: true
    //         });
    //         store.createIndex("name", "name");
    //       }
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   let all = [];
    //   try {
    //     all = await db.getAll(vueThis.dbMidiActionName);
    //   } catch (e) {
    //     // there are currently no stored objects
    //     console.log(e);
    //   }
    //   console.log(all);
    //   return all;
    // },
    // dbGetMidiCommand: async function(id) {},
    // dbRemoveMidiCommand: async function(id) {
    //   let vueThis = this;
    //   const db = await openDB(this.dbName, this.dbVersion, {
    //     upgradeDB(db) {
    //       db.createObjectStore(vueThis.dbStoreName, {
    //         keyPath: "id"
    //       });
    //       db.createObjectStore(vueThis.dbMidiActionName, {
    //         keyPath: "id",
    //         autoIncrement: true
    //       });
    //     }
    //   });
    //
    //   try {
    //     console.log(id);
    //     await db.delete(this.dbMidiActionName, id);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // dbClearMidiCommands: async function() {
    //   let vueThis = this;
    //   let db;
    //   try {
    //     db = await openDB(vueThis.dbName, vueThis.dbVersion, {
    //       upgrade(db) {}
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   try {
    //     await db.clear(vueThis.dbMidiActionName);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
