export default {
  state: () => ({
    controller: {},
    plan: {},
    live: {},
    liveIncluded: [],
    items: [],
    itemTimes: [],
    planTimes: [],
    etag: {
      controller: '',
      itemTimes: '',
      items: '',
      live: '',
    },
    serviceType: '',
    // me: {},
    urls: {
      services: 'https://api.planningcenteronline.com/services/v2'
    }
  }),
  mutations: {
    setLive: (state, payload) => {
      state.live = payload
    },
    setLiveIncluded: (state, payload) => {
      state.liveIncluded = payload
      state.controller = payload.find(
        ({ type }) => type === 'Person'
      )
      // if (state.controller.person === undefined) {
      //   state.controller.hasControl = false
      // } else {
      //   if (state.controller.person.id === state.me.id) {
      //     // if I am the controller
      //     state.controller.hasControl = true
      //     state.controller.canTakeControl = true
      //   } else {
      //     state.controller.hasControl = false
      //   }
      // }
    },
    setServiceType: (state, payload) => {
      state.serviceType = payload
    },
    setPlan: (state, payload) => {
      state.plan = payload
      state.serviceType = payload.relationships.service_type.data.id
    },
    setPlanTimes: (state, payload) => {
      state.planTimes = payload
    },
    setItems: (state, payload) => {
      state.items = payload
    },
    setItemTimes: (state, payload) => {
      state.itemTimes = payload
    },
    setEtag: (state, { etag, value }) => {
      state.etag[etag] = value
    },
    // setCanTakeControl: (state, payload) => {
    //   state.controller.canTakeControl = payload
    // },
    // setControllerText: (state, payload) => {
    //   state.controller.controlText = state.controlText[payload]
    // },
    // setHasControl: (state, payload) => {
    //   state.controller.hasControl = payload
    // },
    // setMe: (state, payload) => {
    //   state.me = payload
    // },
    setControllerPerson: (state, payload) => {
      state.controller = payload
    },
    reset: (state) => {
      state.controller = {}
      state.plan = {}
      state.live = {}
      state.liveIncluded = []
      state.items = []
      state.itemTimes = []
      state.planTimes = []
      state.etag = {
        controller: '',
        itemTimes: '',
        items: '',
        live: ''
      }
      state.serviceType = ''
    }
  },
  getters: {
    getLive: (state) => state.live,
    getServiceTypeID: (state) => state.serviceType,
    getPlan: (state) => state.plan,
    getPlanID: (state) => state.plan.id,
    getEtags: (state) => state.etag,
    getPlanTimes: (state) => state.planTimes,
    getController: (state) => state.controller,
    // getMe: state => state.me,
    getItemTimes: state => state.itemTimes,
    getItems: state => state.items,
    getLiveIncluded: state => state.liveIncluded,
    // getController: state => state.controller,
    hasControl: (state, getters) => {
      if (state.controller === undefined) {
        return false
      }
      if (state.controller.id === getters.getMe.id) {
        return true
      }
      return false
    },
    canTakeControl: state => {
      if (state.live.attributes.can_control && state.controller === undefined) {
        return true
      }
      return state.live.attributes.can_control && state.live.attributes.can_take_control
    },
    urls: state => state.urls
  },
  actions: {
    toggleControl: async ({ commit, getters, dispatch }) => {
      const toggle = await dispatch('request', {
        url: `${getters.getLive.links.toggle_control}?include=current_item_time,controller`,
        method: 'POST'
      })
      commit('setLive', toggle.response.data)
      commit('setLiveIncluded', toggle.response.included)
    },
    goToNextItem: async ({ getters, dispatch, commit }) => {
      const next = await dispatch('request', {
        url: `${getters.getLive.links.go_to_next_item}?include=current_item_time,controller,next_item_time`,
        method: 'POST'
      })
      commit('setLive', next.response.data)
      commit('setLiveIncluded', next.response.included)
    },
    goToPreviousItem: async ({ dispatch, commit, getters }) => {
      const next = await dispatch('request', {
        url: `${getters.getLive.links.go_to_previous_item}?include=current_item_time,controller`,
        method: 'POST'
      })
      commit('setLive', next.response.data)
      commit('setLiveIncluded', next.response.included)
      return true
    },
    loadPlanTimes: async ({ dispatch, getters, commit }) => {
      try {
        const planTimes = await dispatch('request', {
          url: `${getters.urls.services}/service_types/${getters.getServiceTypeID}/plans/${getters.getPlan.id}/plan_times`
        })

        commit('setPlanTimes', planTimes.response.data)
      } catch (e) {
        commit('setPlanTimes', [])
        throw e
      }
    },
    getItems: async ({ dispatch, getters, commit }) => {
      try {
        const items = await dispatch('request', {
          url: `${getters.urls.services}/service_types/${getters.getServiceTypeID}/plans/${getters.getPlanID}/items?per_page=100&include=item_times`,
          etag: getters.getEtags.items
        })
        if (items.status !== 304) {
          commit('setItems', items.response.data)
          commit('setItemTimes', items.response.included)
          commit('setEtag', {
            etag: 'items',
            value: items.etag
          })
        }
      } catch (e) {
        if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
          throw e
        } else {
          commit('setItems', [])
          commit('setItemTimes', [])
          commit('setEtag', {
            etag: 'items',
            value: ''
          })
        }
        throw e
      }
    },
    getLive: async ({ dispatch, getters, commit }) => {
      try {
        if (getters.getServiceTypeID === '') {
          return
        }
        const live = await dispatch('request', {
          url: `${getters.urls.services}/service_types/${getters.getServiceTypeID}/plans/${getters.getPlanID}/live?include=current_item_time,controller,next_item_time`,
          etag: getters.getEtags.live
        })
        if (live.status !== 304) {
          commit('setLive', live.response.data)
          commit('setLiveIncluded', live.response.included)
          commit('setEtag', {
            etag: 'live',
            value: live.etag
          })

          // if (
          //   this.shouldTakeControl &&
          //   this.currentController.canTakeControl &&
          //   !this.currentController.hasControl
          // ) {
          //   this.toggleControl();
          // }
        }
      } catch (e) {
        if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
          throw e
        } else {
          commit('setLive', {})
          commit('setLiveIncluded', [])
          commit('setEtag', {
            etag: 'live',
            value: ''
          })
        }
        throw e
      }
    }
  }
}
