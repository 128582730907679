<template>
  <transition name="fade">
    <div v-if="!Object.prototype.hasOwnProperty.call(me, 'id')" class="grid-x">
      <div class="cell">
        <div :class="credsStatus.style" class="callout secondary">
          <div v-if="!useAccessToken">
            <h2>Welcome!</h2>
            <p>To get started, this page needs access to your Planning Center Account. Login with your Planning Center Online username and password or you can use a developer access token.</p>

            <button class="button" type="button" @click="pcoLogin" :disabled="isOffline" :title="buttonTitle">Login to PCO</button>
            <button
              class="button small secondary"
              type="button"
              @click="useAccessTokenButton"
              :disabled="isOffline"
              :title="buttonTitle"
            >Use Access Token</button>

            <div v-if="credsStatus.name !== ''" class="creds-error">
              <h3>{{credsStatus.name}}</h3>
              <p>{{credsStatus.message}}</p>
              <button class="button small alert" type="button" @click="cancelOauth">Cancel</button>
            </div>
          </div>
          <div v-else>
            <p>
              Head to
              <a
                href="https://api.planningcenteronline.com/oauth/applications"
                target="_blank"
              >PCO Developer Settings</a>
              to
              setup a Personal Access Token.
            </p>
            <button
              v-if="!showCreds"
              class="button small"
              type="button"
              @click="showCreds = true"
            >I have my token!</button>
            <button
              v-if="!showCreds"
              class="button small secondary"
              type="button"
              @click="useAccessToken = false"
            >Back</button>
            <transition name="expand">
              <div v-if="showCreds">
                You can copy and paste the Application ID and Secret from PCO into here.
                <form @submit.prevent="saveCreds">
                  <div class="grid-container full">
                    <div class="grid-x grid-margin-x">
                      <div class="cell medium-6">
                        <label>
                          Application ID
                          <input
                            v-model.lazy.trim="appID"
                            @input="error.appID = ''"
                            type="text"
                            class="form-appID"
                            :class="credsStatus.style"
                            title="Enter your AppID."
                            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                          />
                          <span v-show="error.appID !== ''" class="form-error">{{error.appID}}</span>
                        </label>
                      </div>
                      <div class="cell medium-6">
                        <label>
                          Secret
                          <input
                            v-model.lazy.trim="secret"
                            @input="error.secret = ''"
                            type="text"
                            class="form-secret"
                            :class="credsStatus.style"
                            title="Enter your Secret."
                            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                          />
                          <span v-show="error.secret !== ''" class="form-error">{{error.secret}}</span>
                        </label>
                      </div>
                    </div>
                    <div class="grid-x grid-margin-x">
                      <transition name="expand">
                        <div v-if="credsStatus.name !== ''" class="cell text-left creds-error">
                          <h4>Status: {{credsStatus.name}}</h4>
                          <p>{{credsStatus.message}}</p>
                        </div>
                      </transition>
                      <div class="cell text-right">
                        <input type="submit" class="button small form-submit" value="Save" />
                        <button @click="cancelCreds()" class="button small alert form-cancel">Cancel</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'welcome-screen-oauth',
  // inject: ["urls", "request"],
  props: {
    // me: {
    //   required: true,
    //   type: Object
    // },
    // auth: {
    //   required: true,
    //   type: Object
    // }
  },
  data() {
    return {
      authData: {},
      appID: '',
      secret: '',
      credLength: 64,
      credsStatus: {
        name: '',
        message: '',
        style: ''
      },
      errorText: {
        length:
          "It looks like what you entered isn't long enough. This isn't your username or password. Try again. Tip: Copy and Paste from the PCO site.",
        same:
          'It looks like your appID and secret are the same. Try again but different this time.'
      },
      error: {
        appID: '',
        secret: ''
      },
      meLocal: this.me,
      showCreds: false,
      showCredsStatus: false,
      useAccessToken: false,
      newWindow: {},
      timeOutMinutes: 3,
      timeOut: {},
      logoutURL: 'connect/pco?custom_params%5Bforce_login%5D=true'
    }
  },
  mounted() {
    // this.loadCreds()
  },
  computed: {
    ...mapGetters({
      auth: 'getAuth',
      me: 'getMe',
      hasControl: 'hasControl',
      isOffline: 'isOffline',
      urls: 'urls'
    }),
  },
  watch: {
    appID: function(newAppID) {
      if (newAppID === this.secret) {
        this.error.secret = this.errorText.same
      } else if (newAppID.length !== this.credLength) {
        this.error.appID = this.errorText.length
      } else {
        // run checker
        this.saveCreds()
      }
    },
    secret: function(newSecret) {
      if (newSecret === this.appID) {
        this.error.secret = this.errorText.same
      } else if (newSecret.length !== this.credLength) {
        this.error.secret = this.errorText.length
      } else {
        // run checker
        this.saveCreds()
      }
    }
  },
  methods: {
    cancelCreds: function() {
      this.appID = ''
      this.secret = ''
      this.showCreds = false
      this.credsStatus.style = ''
      this.credsStatus.name = ''
      this.credsStatus.message = ''
      this.error = {
        appID: '',
        secret: ''
      }
    },
    saveCreds: async function() {
      if (
        this.appID.length !== this.credLength
        || this.secret.length !== this.credLength
      ) {
        return
      }
      this.credsStatus.name = 'Checking PCO'
      this.credsStatus.message = ''
      this.credsStatus.style = ''

      // const vueThis = this
      // check to see if the creds are actually correct
      await this.getMe()
      if (Object.prototype.hasOwnProperty.call(this.meLocal, 'id')) {
        this.$store.commit('setBasic', {
          appID: this.appID,
          secret: this.secret,
        })
        this.$store.commit('setMe', this.meLocal)
        this.$emit('update')
        // this.storeCreds();
        // this.$emit("update:me", this.meLocal);
        // this.$emit("update:creds", {
        //   appID: this.appID,
        //   secret: this.secret,
        //   authData: this.authData
        // });
      }
    },
    // storeCreds: function() {
    //   if (this.authData.hasOwnProperty("access_token")) {
    //     window.localStorage.setItem("auth-data", JSON.stringify({
    //       access_token: this.authData.access_token,
    //       raw: {
    //         token_type: this.authData.raw.token_type,
    //         created_at: this.authData.raw.created_at,
    //         expires_in: this.authData.raw.expires_in
    //       }
    //     }))
    //   } else {
    //     window.localStorage.setItem("pco-appID", this.appID);
    //     window.localStorage.setItem("pco-secret", this.secret);
    //   }
    // },
    loadCreds: async function() {
      this.appID = window.localStorage.getItem('pco-appID')
      this.secret = window.localStorage.getItem('pco-secret')
      if (this.appID) {
        // this.me.id = 1;
        await this.getMe()
        if (Object.prototype.hasOwnProperty.call(this.meLocal, 'id')) {
          this.$emit('update:me', this.meLocal)
          this.$emit('update:creds', {
            appID: this.appID,
            secret: this.secret,
            authData: this.authData
          })
        }
      } else {
        this.appID = ''
        this.secret = ''
      }
    },
    resetCreds: async function() {
      // window.localStorage.removeItem("auth-data")
      this.authData = {}
      this.meLocal = {}
      // window.open(this.logoutURL)

      this.appID = ''
      this.secret = ''
      // window.localStorage.removeItem("pco-appID");
      // window.localStorage.removeItem("pco-secret");

      this.$store.commit('clearCreds')
      this.currentPlan = {}
    },
    pcoLogin: async function() {
      this.credsStatus.name = 'Checking PCO'
      this.credsStatus.message = ''
      this.credsStatus.style = ''

      window.addEventListener('storage', this.receiveMessage, false)
      this.newWindow = window.open('connect/pco')

      // start a timeout timer of x minutes
      this.timeOut = setTimeout(() => {
        // show an error and tell them to try again
        this.credsStatus.name = 'An Error Occured'
        this.credsStatus.message = 'Hmm something went wrong. It may have taken too long. You can try again.'
        this.credsStatus.style = ''
        clearInterval(this.checkAuthInterval)
      }, this.timeOutMinutes * 60 * 1000)
    },
    receiveMessage: function() {
      this.authData = JSON.parse(window.localStorage.getItem('authData'))
      window.localStorage.removeItem('authData')
      this.newWindow.close()
      window.removeEventListener('storage', this.receiveMessage, false)

      if (Object.prototype.hasOwnProperty.call(this.authData, 'access_token')) {
        this.meLocal = this.authData.profile.data
        this.$store.commit('setOauth', this.authData)
        this.$store.commit('setMe', this.meLocal)
        this.$emit('update')
        // this.storeCreds();
        // this.$emit("update:me", this.meLocal);
        // this.$emit("update:creds", {
        //   appID: this.appID,
        //   secret: this.secret,
        //   authData: this.authData
        // });
        this.credsStatus.name = ''
        this.credsStatus.message = ''
        this.credsStatus.style = ''
      } else {
        this.credsStatus.name = 'An Error Occured'
        this.credsStatus.message = 'Hmm something went wrong. You can try again.'
        this.credsStatus.style = ''
      }

      clearTimeout(this.timeOut)
    },
    useAccessTokenButton: function () {
      this.cancelOauth()
      this.useAccessToken = true
    },
    cancelOauth: function() {
      this.credsStatus.name = ''
      this.credsStatus.message = ''
      this.credsStatus.style = ''
      window.removeEventListener('message', this.receiveMessage, false)

      clearTimeout(this.timeOut)
    },
    getMe: async function() {
      try {
        const authHeader = {
          Authorization: `Basic ${window.btoa(`${this.appID}:${this.secret}`)}`
        }
        const send = {
          method: 'GET',
          headers: authHeader
        }
        const response = await fetch(`${this.urls.services}/me`, send)

        const { status } = response
        if (
          status === 400
          || status === 401
          || status === 500
          || status === 503
        ) {
          const returnError = Error(response.message)
          returnError.name = 'test'
          console.error(response)

          throw returnError
        }

        const json = await response.json()
        this.credsStatus.name = 'Success'
        this.credsStatus.message = ''
        this.credsStatus.style = 'success'

        // Wait to show the user that it was successful
        await new Promise((resolve) => {
          setTimeout(() => {
            this.meLocal = json.data
            resolve()
          }, 500)
        })
      } catch (e) {
        if (e.message === 'Failed to fetch') {
          this.credsStatus.name = 'Invalid Credentials'
          this.credsStatus.message = 'Try again. You may have swapped them.'
        } else {
          this.credsStatus.name = e.name
          this.credsStatus.message = e.message
        }

        this.credsStatus.style = 'is-invalid-input'
        this.meLocal = {
          attributes: {
            full_name: '',
            photo_thumbnail_url: ''
          }
        }
      }
    },
    buttonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      return '' // default
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-error {
  display: block;
}
</style>
