<template>
  <div class="title-bar">
    <div class="title-bar-left">
      <button
        v-if="Object.prototype.hasOwnProperty.call(me, 'id')"
        @click="$emit('show-settings', true)"
        class="menu-icon"
        type="button"
        title="Settings"
      ></button>
      <div class="title-bar-title">
        <slot></slot>
      </div>
    </div>
    <div class="top-bar-right">
      <ul class="menu" v-if="Object.prototype.hasOwnProperty.call(me, 'id')">
        <li v-if="plan.hasOwnProperty('id')">
          <button
            v-if="isOffline"
            class="button margin-right-1"
            :disabled="true"
            type="tiny button"
            title="Browser Offline"
          >
            Offline
          </button>
          <button
            v-else-if="!shouldAutoUpdate"
            @click="$emit('auto-update')"
            class="button margin-right-1"
            type="tiny button"
            title="Connect to PCO for updates and enable this application to change PCO"
          >
            Connect
          </button>
          <button
            v-else
            @click="$emit('auto-update', false)"
            class="button margin-right-1"
            type="tiny button"
            title="Stop receiving updates from PCO and disable this application from making any changes to PCO"
          >
            Disconnect
          </button>
        </li>
        <li>
          <img
            class="user-thumbnail"
            :src="me.attributes.photo_thumbnail_url"
            height="38"
            width="38"
            alt="User Thumbnail"
          />
        </li>
        <li>
          <dropdown :button-text="me.attributes.full_name" position="right">
            <a v-if="planUrl !== ''" :href="planUrl" target="_blank"
              >Open in Planning Center</a
            >
            <a v-if="liveUrl !== ''" :href="liveUrl" target="_blank"
              >Open in PCO Live</a
            >
            <a @click="$emit('reset-creds')">Signout</a>
          </dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Dropdown from './dropdown.vue'

export default {
  name: 'top-bar',
  components: {
    Dropdown,
  },
  props: {
    me: {
      type: Object,
      default: () => ({}),
    },
    shouldAutoUpdate: {
      type: Boolean,
      default: false,
    },
    isOffline: {
      type: Boolean,
      default: false,
    },
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    planUrl: function () {
      if (Object.prototype.hasOwnProperty.call(this.plan, 'id')) {
        return `https://services.planningcenteronline.com/plans/${this.plan.id}`
      }
      return ''
    },
    liveUrl: function () {
      if (Object.prototype.hasOwnProperty.call(this.plan, 'id')) {
        return `https://services.planningcenteronline.com/live/${this.plan.id}`
      }
      return ''
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
