<template>
  <tr :class="{current: isCurrent, excluded: isExcluded}" class="transition-grow item-row">
    <td class="time">{{itemTimeLengthFormatted}}</td>
    <td>
      <h5>{{item.attributes.title}}</h5>
      <span class="description">{{item.attributes.description}}</span>
    </td>
    <td class="text-center">
      <transition name="fade">
        <button
          v-if="!isCurrent"
          @click="goToItem"
          :disabled="disable"
          class="button small"
          type="button"
          name="button"
          :title="buttonTitle"
        >Move here</button>
      </transition>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'item-row',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {
    ...mapGetters({
      // activeService: 'getActiveService',
      currentItemTime: 'getCurrentItemTime',
      hasControl: 'hasControl',
      isOffline: 'isOffline',
      selectedService: 'getSelectedService',
      selectedServiceIndex: 'getSelectedServiceIndex',
      shouldAutoUpdate: 'shouldAutoUpdate',
      itemListWithTimes: 'getItemListWithTimes'
    }),
    disable: function() {
      return (
        this.isOffline
        || !this.hasControl
        || this.isExcluded
        || !this.shouldAutoUpdate
        || this.selectedService === undefined
      )
    },
    buttonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.hasControl) {
        return "You don't have control"
      }
      if (this.isExcluded) {
        return 'Item Excluded'
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.selectedService === undefined) {
        return ''// loading process
      }
      return 'Move to this Position' // default
    },
    itemTimeLengthFormatted: function() {
      const secondsAdjusted = `${this.item.attributes.length % 60}`
      return `${Math.floor(
        this.item.attributes.length / 60
      )}:${secondsAdjusted.padStart(2, '0')}`
    },
    isCurrent: function() {
      // on load, short circuit and just show the list
      if (this.selectedService === undefined) {
        return false
      }
      // if this is the end of the service then it's obviously not current item
      if (this.currentItemTime === undefined) {
        return false
      }

      if (this.getThisItemTime === undefined) {
        return false
      }

      return this.currentItemTime.id === this.getThisItemTime.id
    },
    isExcluded: function() {
      // on load, short circuit and just show the list
      if (this.selectedService === undefined) {
        return false
      }

      if (this.getThisItemTime !== undefined) {
        return this.getThisItemTime.attributes.exclude
      }
      return false
    },
    // find the item time associated with the selected plan_time
    getThisItemTime: function () {
      const item = this.itemListWithTimes.find(tempItem => tempItem.id === this.item.id)
      return item.itemTimes.find(each => each.relationships.plan_time.data.id === this.selectedService.id)
    }
  },
  methods: {
    goToItem: function () {
      this.$store.dispatch('goToItem', this.item.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .excluded {
    /* color: var(--secondary-callout); */
    opacity: .3
  }
</style>
