import Vue from 'vue'
import Vuex from 'vuex'

import vuejsStorage from 'vuejs-storage'

import auth from './modules/auth'
import serviceTypes from './modules/serviceTypes'
import midi from './modules/midi'
import pcoLive from './modules/pcoLive'
import pcoItems from './modules/pcoItems'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    serviceTypes,
    midi,
    pcoItems,
    pcoLive,
    settings
  },
  state: {
    shouldAutoUpdate: false,
    isOffline: false,
    error: Error('')
  },
  plugins: [
    vuejsStorage({
      keys: ['auth', 'settings', 'disabled'],
      namespace: 'pco-live-advanced'
    })
  ],
  mutations: {
    setOffline: (state, payload) => {
      state.isOffline = payload
    },
    setShouldAutoUpdate: (state, payload) => {
      state.shouldAutoUpdate = payload
    },
    setError: (state, payload) => {
      state.error = payload
    }
  },
  getters: {
    isOffline: state => state.isOffline,
    shouldAutoUpdate: state => state.shouldAutoUpdate,
    getError: state => state.error
  },
  actions: {
  }
})
