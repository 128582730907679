<template>
  <div class="card" v-if="live !== undefined && live.hasOwnProperty('id')">
    <div class="card-section">
      <h3 class="separator-center">Controller</h3>
      <div
        class="controller-info"
        v-if="controller !== undefined && controller.hasOwnProperty('id')"
      >
        <img
          :src="controller.attributes.photo_thumbnail_url"
          class="controller-thumbnail"
          height="38"
          width="38"
          alt="User Thumbnail"
        />
        <span class="controller-name">{{
          controller.attributes.full_name
        }}</span>
      </div>
      <div class="no-controller" v-else>No one is controlling</div>
    </div>
    <div class="card-section">
      <transition name="fade">
        <div v-if="hasControl" class="expanded button-group">
          <button
            :disabled="isStart || isOffline || !shouldAutoUpdate"
            type="button"
            class="button previous"
            @click="$emit('go-to-previous-item', true)"
            :title="previousButtonTitle"
          >
            Previous
          </button>
          <button
            :disabled="isEnd || isOffline || !shouldAutoUpdate"
            type="button"
            class="button next"
            @click="$emit('go-to-next-item', true)"
            :title="nextButtonTitle"
          >
            Next
          </button>
        </div>
      </transition>
      <button
        type="button"
        class="expanded button toggle"
        :disabled="toggleButtonDisable"
        @click="$emit('toggle-control')"
        :title="toggleButtonTitle"
      >
        {{ getControlText }}
      </button>
    </div>

    <!-- <div class="card-section">
      <div class="expanded button-group">
        <transition name="fade">
          <button
            v-if="currentController.hasControl"
            :disabled="currentTime.isStart || isOffline"
            type="button"
            class="button"
            @click="goToStart()"
          >
            Reset Service
          </button>
        </transition>
        <transition name="fade">
          <button
            v-if="currentController.hasControl"
            type="button"
            class="button"
            :disabled="currentTime.isEnd || isOffline"
            @click="goToEnd()"
          >
            Go To End
          </button>
        </transition>
      </div>
    </div> -->

    <div class="card-section">
      <h6 v-if="!canMidi">Midi not supported in this browser.</h6>
      <template v-else-if="midiEnabled">
        <h5>Listening for Midi notes</h5>
        <h6 class="subheader">{{ withinServiceText }}</h6>
      </template>
      <h5 v-else>Midi is currently OFF</h5>
      <div v-if="canMidi" class="switch rounded">
        <input
          class="switch-input"
          type="checkbox"
          id="midiSwitch"
          v-model="midiEnabled"
          name="midiSwitch"
        />
        <label class="switch-paddle" for="midiSwitch" title="Toggle Midi">
          <span class="show-for-sr">Toggle Midi</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'controller-live',
  props: {
    canMidi: Boolean,
  },
  data() {
    return {
      controlText: ['Take Control', 'Release Control', "Can't Take Control"],
    }
  },
  methods: {
    // goToStart: function() {
    //   this.$emit('go-to-start', this.selected)
    // },
    // goToEnd: function() {
    //   this.$emit('go-to-end', this.selected)
    // }
  },
  computed: {
    ...mapGetters({
      isOffline: 'isOffline',
      controller: 'getController',
      hasControl: 'hasControl',
      live: 'getLive',
      me: 'getMe',
      isStart: 'isStart',
      isWithinService: 'isWithinService',
      isEnd: 'isEnd',
      shouldAutoUpdate: 'shouldAutoUpdate',
      canTakeControl: 'canTakeControl',
    }),
    midiEnabled: {
      get: function () {
        return this.$store.getters.getMidiEnabled
      },
      set: function (newValue) {
        return this.$store.commit('setMidiEnabled', newValue)
      },
    },
    getControlText: function () {
      if (
        this.controller !== undefined
        && Object.prototype.hasOwnProperty.call(this.controller, 'id')
      ) {
        // if there is a controller
        if (this.controller.id === this.me.id) {
          // if I am the controller
          return this.controlText[1]
        }
      } else if (this.live.attributes.can_control) {
        // If there is no controller and the user can control
        return this.controlText[0]
      }

      if (this.canTakeControl) {
        // If I am an admin then I can always take control
        return this.controlText[0]
      }
      // if (this.live.attributes.can_control && this.controller === undefined) {
      //   return this.controlText[0]
      // }
      return this.controlText[2]
    },
    toggleButtonDisable: function () {
      return (
        this.isOffline
        || !this.shouldAutoUpdate
        || (!this.canTakeControl && !this.hasControl)
      )
    },
    previousButtonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.isStart) {
        return 'Already at the Start'
      }

      return 'Move to Previous Item'
    },
    nextButtonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.isEnd) {
        return 'Already at the End'
      }

      return 'Move to Next Item'
    },
    toggleButtonTitle: function () {
      if (this.isOffline) {
        return 'Browser Offline'
      }
      if (!this.shouldAutoUpdate) {
        return 'Disconnected from PCO'
      }
      if (this.hasControl) {
        return 'Release Control of PCO Live'
      }

      if (!this.live.attributes.can_control) {
        // if the user can't control
        return "You don't have Control Permissions for this Service Type"
      }
      if (this.controller === undefined) {
        // If no one is controlling and you can control
        return 'Take Control of PCO Live'
      }
      if (
        !this.hasControl // if the user doesn't already have control
        && !this.canTakeControl
      ) {
        // if you are not an Administrator
        return "You can't take control from an Administrator"
      }
      return 'Take Control of PCO Live'

      // return ''
    },
    withinServiceText: function () {
      if (this.isWithinService) {
        return 'Within Service (midi actions enabled)'
      }
      return 'Not Within Service (midi actions disabled)'
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
