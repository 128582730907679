<template>
  <tr>
    <td>{{ serviceType.name }}</td>
    <td>{{ serviceType.id }}</td>
    <td>{{ serviceType.folder.name }}</td>
    <td><a :href="pcoLink" target="_blank">Folder</a></td>
    <td v-if="editor">
      <button
        @click="$emit('remove-service', serviceType.id)"
        class="alert small button"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'settings-service-type',
  props: ['serviceType', 'editor'],
  data() {
    return {}
  },
  computed: {
    pcoLink: function () {
      return `https://services.planningcenteronline.com/dashboard/${this.serviceType.folder.id}`
    },
  },
  methods: {
    saveServicePlan: function () {
      this.$emit('save-service', this.serviceType.id)
      this.serviceType.show = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
